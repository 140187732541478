const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const FAQ_REQUEST = "FAQ_REQUEST";
const FAQ_SUCCESS = "FAQ_SUCCESS";
const FAQ_ERROR = "FAQ_ERROR";
const FAQ_UPDATE = "FAQ_UPDATE";
const FAQ_UPDATE_SUCCESS = "FAQ_UPDATE_SUCCESS";
const FAQ_UPDATE_ERROR = "FAQ_UPDATE_ERROR";
const FAQ_CREATE = "FAQ_CREATE";
const FAQ_CREATE_SUCCESS = "FAQ_CREATE_SUCCESS";
const FAQ_CREATE_ERROR = "FAQ_CREATE_ERROR";
const FAQ_DELETE = "FAQ_DELETE";
const FAQ_DELETE_SUCCESS = "FAQ_DELETE_SUCCESS";
const FAQ_DELETE_ERROR = "FAQ_DELETE_ERROR";


import Vue from "vue";
import axios from 'axios';

const state = { status: "", faq: [] };

const getters = {
    searchArticles: (state) => (topic) => {
        return (state.faq === null ? {} : state.faq.find( article => article.tags.includes(topic)))
    },
    getArticle: (state) => (articleid) => {
        return (state.faq === null ? {} : state.faq.find( article => article.faqid == articleid))
    },
    getAllArticles: (state) => state.faq,
};

const actions = {
    [FAQ_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(FAQ_REQUEST);
            commit('addLoading', {handle:'faq',name:'Loading FAQ Articles'})
            axios({url: Vue.prototype.$apiServer + 'faqs', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'faq')
                    if (resp.data.result === "Success") {
                        commit(FAQ_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(FAQ_ERROR)
                        reject(resp.data)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    commit(FAQ_ERROR);
                    commit('removeLoading', 'faq')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [FAQ_UPDATE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(FAQ_UPDATE);
            commit('addLoading', {handle:'faqupdate'+payload.faqid,name:'Updating FAQ Article'})
            axios({
                url: Vue.prototype.$apiServer + 'faq/' + payload.faqid,
                method: "PUT",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'faqupdate'+payload.faqid)
                    if (resp.data.result === 'Success') {
                        commit(FAQ_UPDATE_SUCCESS, resp.data);
                        dispatch(FAQ_REQUEST);
                        resolve(resp)
                    } else {
                        commit(FAQ_UPDATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(FAQ_UPDATE_ERROR);
                    commit('removeLoading', 'faqupdate'+payload.faqid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [FAQ_CREATE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(FAQ_CREATE);
            commit('addLoading', {handle:'faqcreate',name:'Creating FAQ Article'})
            axios({
                url: Vue.prototype.$apiServer + 'faq',
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'faqcreate')
                    if (resp.data.result === 'Success') {
                        commit(FAQ_CREATE_SUCCESS, resp.data);
                        dispatch(FAQ_REQUEST);
                        resolve(resp)
                    } else {
                        commit(FAQ_CREATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(FAQ_CREATE_ERROR);
                    commit('removeLoading', 'faqcreate')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [FAQ_DELETE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(FAQ_DELETE);
            commit('addLoading', {handle:'faqdelete'+payload,name:'Deleting FAQ Article'})
            axios({
                url: Vue.prototype.$apiServer + 'faq/' + payload,
                method: "DELETE"
            })
                .then(resp => {
                    commit('removeLoading', 'faqdelete'+payload)
                    if (resp.data.result === 'Success') {
                        commit(FAQ_DELETE_SUCCESS, resp.data);
                        dispatch(FAQ_REQUEST);
                        resolve(resp);
                    } else {
                        commit(FAQ_DELETE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(FAQ_DELETE_ERROR);
                    commit('removeLoading', 'faqdelete'+payload)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    }
};

const mutations = {
    [FAQ_REQUEST]: (state) => {
        state.status = "loading";
    },
    [FAQ_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "faq", resp.payload);
    },
    [FAQ_ERROR]: (state) => {
        state.status = "error";
      },
    [FAQ_UPDATE]: state => {
        state.status = "updating";
    },
    [FAQ_UPDATE_SUCCESS]: (state) => {
        state.contactupdatestatus = "success";
        //    Vue.set(state, "sites", resp);
    },
    [FAQ_UPDATE_ERROR]: state => {
        state.contactupdatestatus = "error";
    },
    [FAQ_DELETE]: state => {
        state.status = "deleting";
    },
    [FAQ_DELETE_SUCCESS]: (state) => {
        state.contactdeletestatus = "success";
        //    Vue.set(state, "sites", resp);
    },
    [FAQ_DELETE_ERROR]: (state) => {
        state.contactdeletestatus = "error";
        //    Vue.set(state, "sites", resp);
    },
    [FAQ_CREATE]: () => {
        state.contactcreatestatus = "loading";
    },
    [FAQ_CREATE_SUCCESS]: () => {
        state.contactcreatestatus = "success";
    },
    [FAQ_CREATE_ERROR]: () => {
        state.contactcreatestatus = "error";
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.contacts = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};