<template>
  <b-modal :id="modalID" :ref='modalID' :name="modalID" @shown="resize()" title="Request Site Access" size="xl" ok-only
           ok-variant="secondary" ok-title="Cancel">
    <section class="wizard">
      <div class="custom-wizard ml-0 mr-0">
          
        <vue-good-wizard :steps="steps" :onNext="nextClicked" :onBack="backClicked">
          <div slot="page1">
            <section>
                <div class="form-row mb-1">
                    <div class="form-col mb-4 col-4">
                        <div class="form-row">
                            <label for="siteA">Site</label>
                            <multiselect v-model="siteSelection" :multiple="false" required label="sitename" track-by="sitenumber"
                                        :close-on-select="true" :clear-on-select="false" placeholder="Select Site"
                                        :preserve-search="true" :options="siteList" id="siteA">
                                <template slot="tag">{{ '' }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="form-col mb-4 ml-1 col-4">
                        <label for="eType">Attending Technician</label>
                        <b-form-input type="text" v-model="attendingTech"></b-form-input>
                    </div>
                    <div class="form-col col-3">
                        <label for="eType">Zendesk Ticket (Optional)</label>
                        <b-form-input type="number" min="0" max="9999" v-model="zTicket" :number="true" />
                    </div>           
                </div>
                <div class="form-row">
                  <label for="SettingsPane">Settings</label>
                  <div label="SettingsPane" class="form-col mb-4"> 
                    <switches label="CC Secondary Contacts?" v-model="addCCCheckbox" theme="bootstrap" color="primary" class="pt-3 pl-2 pr-2" />
                    <switches label="Emergency Access?" v-model="addEmergencyCheckbox" theme="bootstrap" color="danger" class="pt-3 pl-2 pr-2" />
                    <switches label="Truncate Primary Contact Name?" v-model="truncatenameCheckbox" theme="bootstrap" color="primary" class="pt-3 pl-2 pr-2" />
                  </div>
                </div>
                <div class="form-row mb-3">
                    <div class="col-6 col-md-6 form-group">
                      <label for="attendanceTime">Attending Time</label>
                      <b-form-timepicker type="time" id="attendanceTime" v-model="attendanceTime"></b-form-timepicker>
                    </div>
                    <div class="col-6 col-md-6 form-group">
                      <label for="attendanceDate">Attending Date</label>
                      <b-form-datepicker type="date" id="attendanceDate" v-model="attendanceDate"></b-form-datepicker>
                    </div>
                </div>
                <div class="form-row mb-3" v-if="site.accesstype == 3">
                  <div class="form-col mb-4 col-6">
                    <label for="attendanceTime" class="text-danger">Site Access required via customer-operated portal</label>
                  </div>
                </div>
                <div class="form-row mb-3" v-else-if="site.accesstype == 5">
                  <div class="form-col mb-4 col-6">
                    <label for="attendanceTime" class="text-danger">Site Access required via other methods</label>
                  </div>
                </div>
                <div class="form-row mb-3" v-else-if="site.accesstype !== 0">
                  <div class="form-col mb-4 col-6">
                    <div class="form-row" v-if="site.accesstype !== 2">
                      <label for="siteA">Access Request Prefill Selection</label>
                      <multiselect v-model="selectedAccessReason" :multiple="false" required label="title" track-by="title"
                                  :close-on-select="true" :clear-on-select="false" placeholder="Select Access Reason"
                                  :preserve-search="true" :options="accessReasons" id="title">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                    <div class="mt-3 col-12 col-md-12" >
                      <label for="attendanceDescription">Site Access Details</label>
                      <textarea class="form-control" rows="5" id="attendanceDescription" v-model="attendanceDescription"></textarea>
                    </div>
                  </div>
                  <div class="form-col ml-1 col-5" v-if="site.accesstype == 4">
                    <label for="fileUpload">Site Access Request Form</label>
<!--                <b-form-file
                      v-model="file"
                      id="fileUpload"
                      :state="Boolean(file)"
                      placeholder="Attach Customer Site Access Form"
                    /> -->
                    <!-- <input id="input-file" v-on:change="setFileUpload" type="file" /> -->
                    <b-form-file
                      v-on:change="setFileUpload"
                      v-model="fileUploadInput"
                      id="fileUpload[]"
                      name="fileUpload[]"
                      multiple
                      :state="Boolean(fileUploadInput)"
                      placeholder="Attach Customer Site Access Form"
                      drop-placeholder="Drop file here..."
                    />
                  </div>
                </div>
                <div class="form-row mb-3" v-else-if="site.accesstype == 0 && siteSelection.sitename">
                  <div class="form-col mb-4 col-6">
                    <label for="attendanceTime" class="text-danger">Prior Notification is not Required</label>
                  </div>
                </div>
            </section>
          </div>
          <div slot="page2">
            <section>
              <h4 class="pb-3">Site Access Preflight:</h4>
              <b-card no-body>
                     <b-card-body>
                      <div >
                        <div class="row">
                            <div class="col-md-3">
                            Recipient:
                            <br><br>
                            </div>
                            <div class="col-md-9">
                               {{ preflightAccess.requester }}
                            </div>
                        </div>
                        <hr color="gray" v-if="preflightAccess.ccs"/>
                        <div class="row"  v-if="preflightAccess.ccs">
                          <div class="col-md-3">
                              CC's:
                              </div>
                              <div class="col-md-9">
                                <div v-for="thisCC in preflightAccess.ccs" :key="thisCC">
                                {{thisCC}}<br>
                                </div>
                              </div>
                          </div>
                        <hr color="gray" v-if="preflightAccess.attachments"/>
                        <div class="row"  v-if="preflightAccess.attachments">
                            <div class="col-md-3">
                            Attachments:
                            </div>
                            <div class="col-md-9">
                              <div v-for="thisAttachment in preflightAccess.attachments" :key="thisAttachment">
                              {{thisAttachment}}<br>
                              </div>
                            </div>
                        </div>
                        <hr color="gray" />
                        <div class="row"> 
                            <div class="col-md-3">
                            Email Message:
                            <br><br>
                            </div>
                            <div class="col-md-9">
                                <p v-html="htmlComment(preflightAccess.htmlcomment)"></p>
                            </div>
                        </div>
                        <hr color="gray"/>
                        </div>                 
                      </b-card-body>
                  </b-card>
            </section>
          </div>
          <div slot="page3">
            <section>
              
              <b-card no-body>
                     <b-card-body>
                        <div >
                          <h4 class="pb-3">Site Access Lodged in Ticket #{{ preflightAccess.id }}</h4>
                        </div>                 
                      </b-card-body>
                  </b-card>
            </section>
          </div>
        </vue-good-wizard>
      </div>
    </section>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import Switches from 'vue-switches';
import {GoodWizard} from "vue-good-wizard";
import Vue from "vue";
import axios from "axios";

const SITE_ACCESS_CREATE = "SITE_ACCESS_CREATE";
//const SITE_ACCESS_FORM = "SITE_ACCESS_FORM";

export default {
  name: "siteAccessModal",
  props: {
    modalID: String,
    serviceID: String
  },
  components: {
    Multiselect,
    Switches,
    "vue-good-wizard": GoodWizard
  },
  computed: {
    unixAttendTime() {
        var StartValue = new Date(this.attendanceDate + " " + this.attendanceTime)
        return StartValue.getTime()/1000
    },
    pageOneValidation() {
      if(this.site.accesstype == 0 ){
        Vue.prototype.$snotify.warning("A Site Access Request is not required for this site", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
        });
        return false
      } else if(this.site.accesstype == 3 ){
        Vue.prototype.$snotify.warning("Site Access is to be requested via customer system", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
        });
        return false
      } else if(this.site.accesstype == 4 && !this.fileUpload ){
        Vue.prototype.$snotify.warning("Site Access Form must be provided!", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
        });
        return false
      } else if(this.site.accesstype == 5 ){
        Vue.prototype.$snotify.warning("Site Access is to be requested via other means", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
        });
        return false
      }

      if(!this.siteSelection.siteid || !this.attendanceDate || !this.attendanceTime){
        Vue.prototype.$snotify.warning("Site Access Form is Incomplete!", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
        });
        return  false
      }

      if(this.attendanceDescription == "" && (this.site.accesstype == 1 || this.site.accesstype == 4)){
        Vue.prototype.$snotify.warning("Site Access Form is Incomplete!", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
        });
        return  false
      }

    /*   if(this.attendanceComment == "" && this.site.accesstype == 2){
        Vue.prototype.$snotify.warning("Site Access Form is Incomplete!", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
        });
        return  false
      } */
        
     /*  if(!this.eType.value || this.serviceList.length === 0 || !this.attendanceTime || !this.attendanceDate || !this.attendingTech || !this.attendanceDescription || !this.zTicket ) {
        return false
      } else {
        if(this.eType.value !== 3) {
          if(!this.eventEndDate || !this.eventEndTime) {
            return false
          } else {
            return true
          }
        }
        return true
      } */
      return true
    },
    siteContacts(){
        let getContacts = this.$store.getters.getContactsBySite(this.siteSelection.siteid)
        let contactList = []
        for (let i = 0; i < getContacts.length; i++) {
            let thisContact = {contactname: '', contactid: ''}
            thisContact.contactname = getContacts[i].contactname + ": " + getContacts[i].title
            thisContact.contactid = getContacts[i].contactid
            contactList.push(thisContact)
        }
        return contactList
    },
    siteList() {
        //  let getSites = this.$store.getters.getAllSitesByCustomerUUIDList(this.Customer.customerid)
        let getSites = this.$store.getters.getPhysicalSitesList
        let sitesList = []

        for (let i = 0; i < getSites.length; i++) {
            let thisSite = {sitenumber: '', sitename: '', siteid: ''}
            thisSite.sitenumber = getSites[i].sitenumber.substring(1)
            thisSite.sitename = getSites[i].sitenumber + ": " + getSites[i].sitename
            thisSite.siteid = getSites[i].siteid
            sitesList.push(thisSite)
        }

        return sitesList
    },

  },
  data() {
    return {
        finalStepLabel: "Exit",
      //  file: null,
        slaTypeSelection: [],
        siteContactselection: [],
        ePriority: [],
        serviceList: [],
        eType: [],
        createdTicket: 0,
        fileUpload: null,
        fileUploadInput: null,
        preflightAccess:[],
        eventCreated: {
          customers:[]
        },
        initiator: "",
        siteSelection: {},
        site: {
          siteid: "",
          accesstype: 0,
        },
        accessReasons: [
          {
            title: "Preventative Maintenance",
            reason: "We are requesting access onsite in order to conduct preventative maintenance on VERNet equipment"
          },
          {
            title: "Maintenance Works",
            reason: "We are requesting access onsite in order to conduct necessary maintenance works on VERNet equipment"
          },
          {
            title: "Scheduled Works Access",
            reason: "We are requesting access onsite in order to conduct works as part of a planned network event"
          },
          {
            title: "Outage Investigation",
            reason: "We are requesting access onsite in order to investigate the cause of an ongoing network outage"
          },
          {
            title: "Outage Restoration Works",
            reason: "We are requesting access onsite in order to repair a fault in the VERN that is currently impacting customer services"
          },
        ],
        selectedAccessReason: {
          title: "",
          reason: ""
        },
        addCCCheckbox: false,
        addEmergencyCheckbox: false,
        truncatenameCheckbox: false,
        tSubject: '',
        tText: '',
        tTroubleshooting: '',
        zTicket: 0,
        attendanceTime: '',
        attendanceDate: '',
        attendanceComment: '',
        preFlight: false,
        generatedEvent: [],
        attendingTech: '',
        attendanceDescription: '',
        attendanceNote: "",
        steps: [
          {
              label: "Site Access Description",
              slot: "page1"
          },
          {
              label: "Preflight Confirmation",
              slot: "page2"
          },
          {
              label: "Summary",
              slot: "page3",
          }
        ]
    }
  },
  watch: {
    siteSelection: function(newvalue) {
        this.siteContactselection = []
        this.site = this.$store.getters.getSite(newvalue.siteid)
    },
    selectedAccessReason: function(newvalue){
      if(this.site.accesstype !== 2){
        this.attendanceDescription = newvalue.reason
      }
    }
  },
  filters:
      {
        siteAccessType: function (value) {
          if(value){
            switch (value) {
              case 0: {
               return "No Notification Required!"
              }
              case 1: {
               return "Mandatory Email Notification"
              }
              case 2: {
               return "Courtesy Email Notification"
              }
              case 3: {
               return "External Means"
              }
              case 4: {
               return "Access Form Required"
              }
              case 5: {
               return "Other"
              }
              default: {
                return true;
              }
            }

          } else {
            return "Missing!"
          }
        },
      },
  methods: {
    setFileUpload(event){
      this.fileUpload = event.target.files
    //  window.console.log("File Submitted: " + JSON.stringify(this.fileUpload))
    },
   hideModal() {
      this.preFlight = false
      this.preflightAccess = []
      this.generatedEvent = []
      this.attendingTech = ''
      this.createdTicket = 0
      this.attendanceDescription = ''
      this.serviceAffectingCheckbox = false
      this.addCCCheckbox = false
      this.addEmergencyCheckbox = false
      this.truncatenameCheckbox = false
      this.attendanceComment = ""
      this.$refs[this.modalID].hide()
      this.site = {
          siteid: "",
          accesstype: 1,
      }
    },
    htmlComment(comment){
      //  window.console.log("Comment: " + comment)
        if(comment){
          var thisComment = comment.replace(/(?:\r\n|\r|\n)/g, '<br>');
          thisComment = thisComment.replace("/\t", "")
       //   window.console.log("Revised Comment: " + thisComment)
          return thisComment
        }
        else{
          return ""
        }
    },
    createSiteAccess(preflightCheck) {
        let payload = {
            siteID: this.siteSelection.siteid,
            number: this.zTicket,
            technician: this.attendingTech,
            datetime: this.unixAttendTime,
            addccs: this.addCCCheckbox,
            emergency: this.addEmergencyCheckbox,
            truncatename: this.truncatenameCheckbox,
            serviceaffecting: this.serviceAffectingCheckbox,
            preflight: preflightCheck
        }

        if(this.zTicket !== 0){
          payload.ticketid = this.zTicket
        }

        if(this.attendanceDescription !== ""){
          payload.additionaldetails = this.htmlComment(this.attendanceDescription)
        }

        else if(this.attendanceComment !== ""){
          payload.comment = this.attendanceComment
        //  window.console.log("payload HTML Comment")
          payload.htmlcomment = this.htmlComment(this.attendanceComment)
       //   window.console.log("payload HTML comment converted")
        }


        if(this.site.accesstype == 4){
          let formData = new FormData();

          for(let x = 0; x < this.fileUpload.length; x++){
          //  window.console.log("Appending File " + x + ": " + JSON.stringify(this.fileUpload[x]))
            formData.append(this.fileUpload[x].name, this.fileUpload[x]);
          }

          /* for (const value of formData.values()) {
          //  window.console.log("Formdata Value: " + JSON.stringify(value))
          } */

          //  formData.append('file', this.fileUpload);
          axios.post(Vue.prototype.$apiServer + 'site/' + this.siteSelection.siteid + "/access/form", formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => {
            //  window.console.log("File Upload Response: " + JSON.stringify(resp))
              this.$store.dispatch(SITE_ACCESS_CREATE, payload).then(resp => {
                this.preflightAccess = resp.data.payload.ticket.create
              //  window.console.log("Preflight Response: " + JSON.stringify(this.preflightAccess))
              }).catch(err => {
                Vue.prototype.$snotify.warning((err.message ? err.message : 'An error occurred while submitting this access request!'), {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
              });
            })
          }).catch(err => {
            Vue.prototype.$snotify.warning((err.message ? err.message : 'An error occurred while submitting this access request!'), {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
            });
          })
        } else{
          this.$store.dispatch(SITE_ACCESS_CREATE, payload).then(resp => {
              this.preflightAccess = resp.data.payload.ticket.create
          //    window.console.log("Preflight Response: " + JSON.stringify(this.preflightAccess))
          })
            .catch(err => {
            //  window.console.log("Error: " + JSON.stringify(err))
              Vue.prototype.$snotify.warning((err.message ? err.message : 'An error occurred while submitting this event for preflight. Please try again.'), {
                  timeout: 2000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
              });
          })
        }
        /* if(preflightCheck == false)
        {
        //  window.console.log("Clearing Event Information...")
            this.serviceList =  [],
            this.eType = [],
            this.eventCreated = {
                customers:[]
            },
            this.eventCustomers = [],
            this.tSubject = '',
            this.tText = '',
            this.tTroubleshooting = '',
            this.zTicket = 0,
            this.attendanceTime = '',
            this.attendanceDate = '',
            this.eventEndTime = '',
            this.eventEndDate = '',
            this.updateExisting = false,
            this.attendingTech = 'Fibre Works in [SUBURB]',
            this.attendanceDescription = '[Provider] have informed us they will be conducting fibre works in the [LOCATION] area during the outage window.',
            this.eventApproximate = false
        } */


    },
    nextClicked(currentPage) {
      switch (currentPage) {
        case 0: {
          if (!this.pageOneValidation) {
            return false;
          } else {

            this.createSiteAccess(true)
            return true
          }
        }
        case 1: {
          this.createSiteAccess(false)
          return true;      
        }
        default: {
          return true;
        }
      }
    },
    backClicked() {
      return true;
    },
    resize() {
      let evt = document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    },
  },
}
</script>

<style>
.btn.rounded-circle.text-dark {
  color: #888 !important;
}

.btn.rounded-circle.text-muted {
  color: #444 !important;
}
</style>