const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const RACKSPACES_REQUEST = "RACKSPACES_REQUEST";
const RACKSPACES_ERROR = "RACKSPACES_ERROR";
const RACKSPACES_SUCCESS = "RACKSPACES_SUCCESS";
const CROSSCONNECTS_REQUEST = "CROSSCONNECTS_REQUEST";
const CROSSCONNECTS_ERROR = "CROSSCONNECTS_ERROR";
const CROSSCONNECTS_SUCCESS = "CROSSCONNECTS_SUCCESS";

import Vue from "vue";
import axios from 'axios';



const state = {rackspacesstatus: "", rackspaces: [], crossconnectsstatus: "", crossconnects: []};

const getters = {
    getRackspacesStatus: state => state.rackspacesstatus,
    getRackspaces: state => {
        return (state.rackspaces ? state.rackspaces : [])
    },
    getRackspaceCrossconnects: (state) => (rackspaceID) => {
        return (state.crossconnects === null ? [] :state.crossconnects.filter(crossconnect => {
            return crossconnect.aendrackspaceid === rackspaceID || crossconnect.bendrackspaceid === rackspaceID
        }))
    },
    getCrossconnectsStatus: state => state.crossconnectsstatus,
    getCrossconnects: state => {
        return (state.crossconnects === null ? [] : state.crossconnects)
    },
    getCrossconnect: (state) => (crossconnectUUID) => {
        return (state.crossconnects === null ? [] : state.crossconnects.find(crossconnect => crossconnect.uuid === crossconnectUUID))
    },
};

const actions = {
    [RACKSPACES_REQUEST]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(RACKSPACES_REQUEST);
            commit('addLoading', {handle:'rackspaces',name:'Load Rackspaces'})
            axios({url: Vue.prototype.$apiServer + 'rackspaces', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'rackspaces')
                    if(resp.data.result === 'Success') {
                        commit(RACKSPACES_SUCCESS, resp.data.payload)
                        resolve(resp.data)
                    } else {
                        commit(RACKSPACES_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(RACKSPACES_ERROR);
                    commit('removeLoading', 'rackspaces')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [CROSSCONNECTS_REQUEST]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(CROSSCONNECTS_REQUEST);
            commit('addLoading', {handle:'crossconnects',name:'Load Cross-Connects'})
            axios({url: Vue.prototype.$apiServer + 'crossconnects', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'crossconnects')
                    if(resp.data.result === 'Success') {
                        commit(CROSSCONNECTS_SUCCESS, resp.data.payload)
                        resolve(resp.data)
                    } else {
                        commit(CROSSCONNECTS_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(CROSSCONNECTS_ERROR);
                    commit('removeLoading', 'crossconnects')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
};

const mutations = {
    [RACKSPACES_REQUEST]: state => {
        state.rackspacesstatus = "loading";
    },
    [RACKSPACES_SUCCESS]: (state, resp) => {
        state.rackspacesstatus = "success";
        Vue.set(state, "rackspaces", resp);
    },
    [RACKSPACES_ERROR]: state => {
        state.rackspacesstatus = "error";
    },
    [CROSSCONNECTS_REQUEST]: state => {
        state.crossconnectsstatus = "loading";
    },
    [CROSSCONNECTS_SUCCESS]: (state, resp) => {
        state.crossconnectsstatus = "success";
        Vue.set(state, "crossconnects", resp);
    },
    [CROSSCONNECTS_ERROR]: state => {
        state.crossconnectsstatus = "error";
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.rackspaces = [];
        state.crossconnects = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};