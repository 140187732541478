/* eslin/t-disable promise/param-names */

/* showLoading state is an array of objects of the format:
[
    { handle: 'userprofiles',
    name: 'User profiles' },
    { handle: 'sites',
    name: 'Sites list' }
]

The intention is that you can call the getter isLoading and get true if showLoading.length > 0.

You commit mutations addLoading(

 */

const state = {
    showLoading: [],
    suppressLoading: [],
    willRememberLogin: false,
};

const getters = {
    isLoading: state => (state.showLoading.length > 0),
    getLoading: state => state.showLoading.filter(loadItem => !state.suppressLoading.includes(loadItem.handle)),
    rememberLoading: state => state.willRememberLogin,
};

const actions = {
};

const mutations = {
    addLoading (state, {handle, name}) {
        state.showLoading.push({handle, name})
    },
    changeRememberLogin (state, value) {
        state.willRememberLogin = value
    },
    suppressLoading (state, handle) {
        state.suppressLoading.push(handle)
    },
    removeLoading (state, handle) {
        state.showLoading = state.showLoading.filter(loadItem => { return loadItem.handle !== handle })
    },
    unsuppressLoading(state, handle) {
        state.suppressLoading = state.suppressLoading.filter(loadItem => { return loadItem.handle !== handle })
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};