import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import store from "./store";
import VuePluralize from 'vue-pluralize';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEthernet } from '@fortawesome/free-solid-svg-icons'
import { faServer } from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// const AUTHTOKEN_SUCCESS = "AUTHTOKEN_SUCCESS";
// const USERPROFILE_REQUEST = "USERPROFILE_REQUEST";

library.add(faEthernet)
library.add(faServer)
library.add(faBuilding)
library.add(faLifeRing)
library.add(faPhone)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(VuePluralize);
const moment = require('moment')
require('moment/locale/en-au')

Vue.use(require('vue-moment'), {
  moment
})

//Vue.prototype.$store = store;
//Vue.prototype.$axios = axios;

Vue.prototype.$apiServer = localStorage.getItem('apiurl') || sessionStorage.getItem('apiurl')
if(!Vue.prototype.$apiServer) { Vue.config.devtools === true ? Vue.prototype.$apiServer = '/api/' : Vue.prototype.$apiServer = 'https://api.vernet.net.au/'; }
//Vue.config.devtools === true ? Vue.prototype.$apiServer = '/api/' : Vue.prototype.$apiServer = 'https://api.vernet.net.au/';
//Vue.prototype.$apiServer = 'https://mitch.dev.api.vernet.net.au/';
//Vue.prototype.$apiServer = 'https://api.vernet.net.au/';

Vue.config.productionTip = false

Vue.prototype.$token = localStorage.getItem('user-token') || sessionStorage.getItem('user-token');

Vue.prototype.$serviceTypes= [
  { letter: "A", type: "Unmanaged", color: "unmanaged", hex: "#207a80"},
  { letter: "B", type: "Managed 1G", color: "managed", hex: "#202e80" },
  { letter: "C", type: "Fibre Channel", color: "managed", hex: "#202e80"},
  { letter: "D", type: "Managed 10G", color: "managed", hex: "#202e80" },
  { letter: "E", type: "Hybrid", color: "hybrid", hex: "#2a2080" },
  { letter: "F", type: "VPLS Connection", color: "vpls", hex: "#7a4b9b" },
  { letter: "G", type: "Layer 3 Service", color: "l3vpn", hex: "#9b4b4b" },
  { letter: "H", type: "Internet", color: "l3vpn", hex: "#9b4b4b" },
  { letter: "W", type: "Internet over VPLS", color: "l3vpn", hex: "#9b4b4b" },
  { letter: "Z", type: "Unmanaged", color: "unmanaged", hex: "#207a80" },
  { letter: null, type: "Undefined", color: "unmanaged", hex: "#207a80" },
  { letter: '', type: "Undefined", color: "unmanaged", hex: "#207a80" },
];

Vue.prototype.$contactTypes= [
  { number: 1, type: "Primary", color: "unmanaged" },
  { number: 2, type: "Secondary", color: "managed" },
  { number: 3, type: "Emergency", color: "l3vpn" }
];


Vue.prototype.$ticketTypes= [
  { status: "open", color: "open" },
  { status: "solved", color: "unmanaged" },
  { status: "pending", color: "pending" },
  { status: "hold", color: "unmanaged" },
  { status: "new", color: "open" }
];

Vue.prototype.$isLoading = false;

Vue.prototype.$faqCategory = 0;

Vue.prototype.$siteStatus= [
  { status: "Online", description: "All services operating normally", color: "primary" },
  { status: "Offline", description: "All services leading to this location are currently offline", color: "offline" },
  { status: "Impacted", description: "One or several services leading to this location are currently offline", color: "orangey-dull" }
];

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
