<template>
    <b-modal :id="modalID" :ref='modalID' :title="'Site ' + (checkout ? 'Checkout' : 'Check-In')" @hide="clearModal()" @shown="loadCheckinModal()" hide-footer size="md">
        <b-container fluid>
            <div class="form-row mb-1" v-if="checkout">
                <div class="form-col mb-4 col-12">
                    <div class="form-row">
                        <label>Check-In Time: </label><br/><br/>
                    </div>
                    <div class="form-row">
                        <h4>{{ checkoutObject.checkintime | moment("hh:mm A, DD/MM/YYYY") }}</h4>
                    </div>
                </div>         
            </div>
            <div class="form-row mb-3">
                <div class="form-col mb-4 col-12">
                    <div class="form-row">
                        <label>Site</label>
                        <multiselect v-model="siteSelection" :multiple="false" required label="sitename" track-by="sitenumber"
                                    :close-on-select="true" :clear-on-select="false" placeholder="Select Site"
                                    :preserve-search="true" :options="siteList" id="siteSelection">
                            <template slot="tag">{{ '' }}</template>
                        </multiselect>
                    </div>
                </div>         
            </div>
            <div class="form-row mb-3">
            <div class="col-6 col-md-6 form-group">
            <label for="eventStartTime">Time</label>
            <b-form-timepicker type="time" id="eventStartTime" v-model="checkTime"></b-form-timepicker>
            </div>
            <div class="col-6 col-md-6 form-group">
            <label for="eventStartDate">Date</label>
            <b-form-datepicker type="date" id="eventStartDate" v-model="checkDate"></b-form-datepicker>
            </div>
            </div>
            <div class="form-row mb-3">
            <div class="col-6 col-md-6 form-group">
                <button type="button" class="btn-sm btn-primary btn-icon-text" @click="siteCheckin()">     
                <i class="fa fa-copy cursor-pointer pr-2"></i>
                Submit
                </button>
            </div>
            </div>
        </b-container>
    </b-modal>
</template>

<script>
    import Multiselect from "vue-multiselect";
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import Vue from "vue"
    
    const SITE_CHECKINS_REQUEST_ALL = "SITE_CHECKINS_REQUEST_ALL";
    const SITE_CHECKIN_USER = "SITE_CHECKIN_USER";
    const SITE_CHECKOUT_USER = "SITE_CHECKOUT_USER";

    Vue.use(Snotify, {
    toast: {
      position: SnotifyPosition.leftTop,
    }
  })

    export default {
        name: 'siteCheckinModal',
        components:
        {
            Multiselect,
        },
        props: {
            modalID: String,
            checkedInSite: Object,
        },
        watch:{
            site(){
                this.populateData()
            },
            siteSelection: function(newvalue) {
                this.getCheckins(newvalue)
            },
            checkedInSite: function(thisSite){
            //    let thisSite = this.$store.getters.getSite(thisSiteid)
                if(thisSite){
                    this.siteSelection.sitename = thisSite.sitenumber + ": " + thisSite.sitename
                    this.siteSelection.siteid = thisSite.siteid
                    this.siteSelection.sitenumber = thisSite.sitenumber
                    this.getCheckins(this.siteSelection)
                }
            }
        },
        computed:{
            userprofile() {
                return this.$store.getters.getProfile
            },
            siteList() {
                let getSites = []

                if(this.userprofile.type !== 2){
                    getSites = this.$store.getters.getPhysicalSitesList.filter(thisSite => thisSite.status == 1) 
                } else {
                    getSites = this.$store.getters.getPhysicalSitesList
                }

                getSites = getSites.sort((a, b) => (a.sitenumber > b.sitenumber ? 1 : -1)); 

                let sitesList = []

                for (let i = 0; i < getSites.length; i++) {
                    let thisSite = {sitenumber: '', sitename: '', siteid: ''}
                    thisSite.sitenumber = getSites[i].sitenumber.substring(1)
                    thisSite.sitename = getSites[i].sitenumber + ": " + getSites[i].sitename
                    thisSite.siteid = getSites[i].siteid
                    sitesList.push(thisSite)
                }

                return sitesList
            },
        },
        mounted(){},
        data () {
            return {
                siteSelection: {},
                checkout: false,
                checkTime: "",
                checkDate: "",
                checkoutObject: {}
            }
        },
        methods:{
            getSite(siteid){
                return this.$store.getters.getSite(siteid)
            },
            loadCheckinModal(){
                if(this.checkedInSite){
                    let thisSite = this.checkedInSite
                    if(thisSite){
                        this.siteSelection.sitename = thisSite.sitenumber + ": " + thisSite.sitename
                        this.siteSelection.siteid = thisSite.siteid
                        this.siteSelection.sitenumber = thisSite.sitenumber
                        this.getCheckins(this.siteSelection)
                    //   this.checkout = true
                    }
                }

                let currentTime = new Date()
                this.checkTime = currentTime.toLocaleTimeString('it-IT')
                this.checkDate = currentTime.getFullYear() + "-" + (currentTime.getMonth() + 1)+ "-" + currentTime.getDate();
            },
            getCheckins(thisSite) {
                this.checkinList = this.$store.getters.getSiteCheckins(thisSite.siteid).filter(thisEntry => thisEntry.userid == this.userprofile.userid && thisEntry.checkouttime == null)
                if(this.checkinList.length > 0){   
                    this.checkout = true
                    this.checkoutObject = this.checkinList[0]
                } else {
                    this.checkout = false
                }
            },
            clearModal(){
                this.siteSelection = {
                    sitename: "",
                    siteid: "",
                    sitenumber: ""
                }
                this.checkout = false
            },
            siteCheckin(){
                let checkinMethod = SITE_CHECKIN_USER

                let payload = {
                 siteid: this.siteSelection.siteid, 
                }

                if(this.checkout){
                checkinMethod = SITE_CHECKOUT_USER
                payload.checkouttime = (new Date(this.checkTime + " " + this.checkDate)).getTime()/1000 
                } else {
                payload.checkintime = (new Date(this.checkTime + " " + this.checkDate)).getTime()/1000 
                }

                this.$store.dispatch(checkinMethod, payload)
                .then(() => {
                    this.$store.dispatch(SITE_CHECKINS_REQUEST_ALL)
                    Vue.prototype.$snotify.success(( this.checkout ? "Checked out from " + this.siteSelection.sitename : "Checked into " + this.siteSelection.sitename), {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                    });
                    this.checkout = false
                    this.$refs[this.modalID].hide()
                })
                .catch((resp) => {
                    Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred during " + this.checkout ? "Check-out" : "Check-in" +"!"), {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                    });
                    //this.lightlevel = resp
                });

                },
        }
   }
</script>