const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";
const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
const NOTIFICATION_CREATE = "NOTIFICATION_CREATE";
const NOTIFICATION_CREATE_ERROR = "NOTIFICATION_CREATE_ERROR";
const NOTIFICATION_CREATE_SUCCESS = "NOTIFICATION_CREATE_SUCCESS";
const NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE";
const NOTIFICATION_UPDATE_ERROR = "NOTIFICATION_UPDATE_ERROR";
const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
const NOTIFICATION_DELETE = "NOTIFICATION_DELETE";
const NOTIFICATION_DELETE_ERROR = "NOTIFICATION_DELETE_ERROR";
const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
const USERS_ITEMS_NEEDING_ATTENTION = "USERS_ITEMS_NEEDING_ATTENTION";

import Vue from "vue";
import axios from 'axios';

const state = { status: "", notifications: [], items: [] };

const getters = {
    getNotifications: state => state.notifications,
    isNotificationListLoaded: state => !!state.notifications,
    getNotification: (state)=> (notificationID) => {
        return (state.notifications === null ? {} : state.notifications.find(notification => notification.notificationid === notificationID))
    },
    getNotificationsByUser: (state)=> (userID) => {
        return (state.notifications === null ? [] : state.notifications.filter(notification => notification.userid === userID))
    },
    getNotificationByService: (state)=> (serviceID, userID) => { //We only want the notifications for the user in question.
        return (state.notifications === null ? [] : state.notifications.filter(notification => notification.serviceid === serviceID && notification.userid === userID))
    },
    getAllNotificationByService: (state)=> (serviceID) => { //We only want the notifications for the user in question.
        return (state.notifications === null ? [] : state.notifications.filter(notification => notification.serviceid === serviceID))
    },
};

const actions = {
    [NOTIFICATIONS_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFICATIONS_REQUEST);
            commit('addLoading', {handle: 'notifications', name: 'Load Notifications'})
            axios({
                url: Vue.prototype.$apiServer + '/notifications', 
                method: "GET"
            })
            .then(resp => {
                commit('removeLoading', 'notifications')
                if (resp.data.result === 'Success') {
                    commit(NOTIFICATIONS_SUCCESS, resp.data)
                    resolve(resp.data)
                } else {
                    commit(NOTIFICATIONS_ERROR)
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit(NOTIFICATIONS_ERROR);
                commit('removeLoading', 'notifications')
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },
    [NOTIFICATION_CREATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFICATION_CREATE);
            commit('addLoading', {handle: 'notificationcreation', name: 'Creating Service Notification'})
            axios({
                url: Vue.prototype.$apiServer + 'notification/', 
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'notificationcreation')
                    if (resp.data.result === 'Success') {
                        commit(NOTIFICATION_CREATE_SUCCESS, resp.data)
                        dispatch(NOTIFICATIONS_REQUEST);
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                        resolve(resp.data)
                    } else {
                        commit(NOTIFICATION_CREATE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(NOTIFICATION_UPDATE_ERROR);
                    commit('removeLoading', 'notificationcreation')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [NOTIFICATION_UPDATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFICATION_UPDATE);
            commit('addLoading', {handle: 'notificationupdate', name: 'Updating Notification'})
            axios({
                url: Vue.prototype.$apiServer + '/notification/' + payload.notificationID, 
                method: "POST",
                data: payload.notification
            })
                .then(resp => {
                    commit('removeLoading', 'notificationupdate')
                    if (resp.data.result === 'Success') {
                        commit(NOTIFICATION_UPDATE_SUCCESS, resp.data)
                        dispatch(NOTIFICATIONS_REQUEST);
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                        resolve(resp.data)
                    } else {
                        commit(NOTIFICATION_UPDATE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit('removeLoading', 'notificationupdate')
                    commit(NOTIFICATION_UPDATE_ERROR);
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [NOTIFICATION_DELETE]: ({ commit, dispatch }, notificationID) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFICATION_DELETE);
            commit('addLoading', {handle: 'notificationdelete', name: 'Updating Notification'})
            axios({
                url: Vue.prototype.$apiServer + '/notification/' + notificationID, 
                method: "DELETE",
                data: notificationID
            })
                .then(resp => {
                    commit('removeLoading', 'notificationdelete')
                    if (resp.data.result === 'Success') {
                        commit(NOTIFICATION_DELETE_SUCCESS, resp.data)
                        dispatch(NOTIFICATIONS_REQUEST);
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                        resolve(resp.data)
                    } else {
                        commit(NOTIFICATION_DELETE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(NOTIFICATION_DELETE_ERROR);
                    commit('removeLoading', 'notificationdelete')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
};

const mutations = {
    [NOTIFICATIONS_REQUEST]: state => {
        state.status = "loading";
    },
    [NOTIFICATIONS_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "notifications", resp.payload);
    },
    [NOTIFICATIONS_ERROR]: state => {
        state.status = "error";
    },
    [NOTIFICATION_CREATE]: state => {
        state.notificationCreationStatus = "loading";
    },
    [NOTIFICATION_CREATE_ERROR]: state => {
        state.notificationCreationStatus = "error";
    },
    [NOTIFICATION_CREATE_SUCCESS]: state => {
        state.notificationCreationStatus = "success";
    },
    [NOTIFICATION_DELETE]: state => {
        state.notificationDeletionStatus = "loading";
    },
    [NOTIFICATION_DELETE_ERROR]: state => {
        state.notificationDeletionStatus = "error";
    },
    [NOTIFICATION_DELETE_SUCCESS]: state => {
        state.notificationDeletionStatus = "success";
    },
    [NOTIFICATION_UPDATE]: state => {
        state.notificationUpdateStatus = "loading";
    },
    [NOTIFICATION_UPDATE_ERROR]: state => {
        state.notificationUpdateStatus = "error";
    },
    [NOTIFICATION_UPDATE_SUCCESS]: (state, resp) => {
        state.notificationUpdateStatus = "success";
        Vue.set(state, "notifications", resp.payload);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};