const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const CONTACTS_REQUEST = "CONTACTS_REQUEST";
const CONTACT_UPDATE = "CONTACT_UPDATE";
const CONTACT_DELETE = "CONTACT_DELETE";
const CONTACT_CREATE = "CONTACT_CREATE";
const CONTACT_CREATE_SUCCESS = "CONTACT_CREATE_SUCCESS";
const CONTACT_CREATE_ERROR = "CONTACT_CREATE_ERROR";
const CONTACT_PUT_SUCCESS = "CONTACT_PUT_SUCCESS";
const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
const CONTACT_DELETE_ERROR = "CONTACT_DELETE_ERROR";
const CONTACT_UPDATE_ERROR = "CONTACT_UPDATE_ERROR";
const CONTACTS_ERROR = "CONTACTS_ERROR";
const CONTACTS_SUCCESS = "CONTACTS_SUCCESS";
const SITE_CONTACT_ASSIGN = "SITE_CONTACT_ASSIGN";
const SITE_CONTACT_ASSIGN_SUCCESS = "SITE_CONTACT_ASSIGN_SUCCESS";
const SITE_CONTACT_ASSIGN_ERROR = "SITE_CONTACT_ASSIGN_ERROR";
const SITE_CONTACT_DEASSIGN = "SITE_CONTACT_DEASSIGN";
const SITE_CONTACT_DEASSIGN_SUCCESS = "SITE_CONTACT_DEASSIGN_SUCCESS";
const SITE_CONTACT_DEASSIGN_ERROR = "SITE_CONTACT_DEASSIGN_ERROR";
const USERS_ITEMS_NEEDING_ATTENTION = "USERS_ITEMS_NEEDING_ATTENTION";
//const EVENTS_REQUEST = "EVENTS_REQUEST";
import Vue from "vue";
import axios from 'axios';

const state = {status: "", contactsitestatus: "", contactupdatestatus: '', contactdeletestatus: '', contacts: []};

const getters = {
    getContactsStatus: state => state.status,
    getContacts: state => {
        return (state.contacts === null ? [] : state.contacts)
    },
    getContact: (state) => (contactid) => {
        return (state.contacts === null ? {} : state.contacts.find(contact => contact.contactid === contactid))
    },
    getContactsBySite: (state) => (siteId) => {
        return (state.contacts === null ? [] : state.contacts.filter(contact => contact.sites.find(site => site.siteid === siteId)))
    },
    getContactsByCustomer: (state) => (customerUUID) => {
        return (state.contacts === null ? [] : state.contacts.filter(contact => contact.customerid === customerUUID))
    },
    searchContacts: (state) => searchParam => {
        let searchTerm = new RegExp(searchParam, "i") //Events are searched by title, ticket number, description, and affected services
        return (state.contact === null ? [] : state.contacts.filter((contact => searchTerm.test(contact.contactname)|| searchTerm.test(contact.email) || searchTerm.test(contact.title) || searchTerm.test(contact.office) || searchTerm.test(contact.mobile))))
    },
};

const actions = {
    [CONTACTS_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(CONTACTS_REQUEST);
            commit('addLoading', {handle:'contacts',name:'Load Site Contacts'})
            axios({url: Vue.prototype.$apiServer + 'contacts', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'contacts')
                    if (resp.data.result === "Success") {
                        commit(CONTACTS_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(CONTACTS_ERROR)
                        reject(resp.data)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    commit(CONTACTS_ERROR);
                    commit('removeLoading', 'contacts')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [CONTACT_UPDATE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(CONTACT_UPDATE);
            commit('addLoading', {handle:'contactupdate'+payload.contactID,name:'Update Site Contact'})
            axios({
                url: Vue.prototype.$apiServer + 'contact/' + payload.contactID,
                method: "PUT",
                data: payload.thisContact
            })
                .then(resp => {
                    commit('removeLoading', 'contactupdate'+payload.contactID)
                    if (resp.data.result === 'Success') {
                        commit(CONTACT_PUT_SUCCESS, resp.data);
                        resolve(resp)
                    } else {
                        commit(CONTACT_UPDATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(CONTACT_UPDATE_ERROR);
                    commit('removeLoading', 'contactupdate'+payload.contactID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [CONTACT_CREATE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(CONTACT_CREATE);
            commit('addLoading', {handle:'contactcreate',name:'Create Site Contact'})
            axios({
                url: Vue.prototype.$apiServer + 'contact',
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'contactcreate')
                    if (resp.data.result === 'Success') {
                        commit(CONTACT_CREATE_SUCCESS, resp.data);
                        resolve(resp)
                    } else {
                        commit(CONTACT_CREATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(CONTACT_CREATE_ERROR);
                    commit('removeLoading', 'contactcreate')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [CONTACT_DELETE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(CONTACT_DELETE);
            commit('addLoading', {handle:'contactdelete'+payload,name:'Delete Site Contact'})
            axios({
                url: Vue.prototype.$apiServer + 'contact/' + payload,
                method: "DELETE"
            })
                .then(resp => {
                    commit('removeLoading', 'contactdelete'+payload)
                    if (resp.data.result === 'Success') {
                        commit(CONTACT_DELETE_SUCCESS, resp.data);
                        resolve(resp);
                    } else {
                        commit(CONTACT_DELETE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(CONTACT_DELETE_ERROR);
                    commit('removeLoading', 'contactdelete'+payload)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITE_CONTACT_ASSIGN]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit('addLoading', {handle:'contactassign'+ payload.siteid + payload.contactid, name:'Assign Site Contact'})
      
            axios({
                url: Vue.prototype.$apiServer + 'site/' + payload.siteid + '/contact/',
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'contactassign'+ payload.siteid + payload.contactid)
                    if (resp.data.result === 'Success') {
                        commit(SITE_CONTACT_ASSIGN_SUCCESS, resp.data);
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                        resolve(resp)
                    } else {
                        commit(SITE_CONTACT_ASSIGN_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SITE_CONTACT_ASSIGN_ERROR);
                    commit('removeLoading', 'contactassign'+ payload.siteid + payload.contactid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITE_CONTACT_DEASSIGN]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(SITE_CONTACT_DEASSIGN);
            commit('addLoading', {handle:'contactdeassign'+ payload.siteid + payload.contactid, name:'Unassign Site Contact'})
            axios({
                url: Vue.prototype.$apiServer + 'site/' + payload.siteid + '/contact/' + payload.contactid,
                method: "DELETE"
            })
            .then(resp => {
                commit('removeLoading', 'contactdeassign'+ payload.siteid + payload.contactid)
                if (resp.data.result === 'Success') {
                    commit(SITE_CONTACT_DEASSIGN_SUCCESS, resp.data);
                    dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                    resolve(resp)
                } else {
                    commit(SITE_CONTACT_DEASSIGN_ERROR);
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit(SITE_CONTACT_DEASSIGN_ERROR);
                commit('removeLoading', 'contactdeassign'+ payload.siteid + payload.contactid)
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },

};

const mutations = {
    [CONTACTS_REQUEST]: state => {
        state.status = "loading";
    },
    [CONTACT_UPDATE]: state => {
        state.status = "updating";
    },
    [CONTACT_DELETE]: state => {
        state.status = "deleting";
    },
    [CONTACTS_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "contacts", resp.payload);
    },
    [CONTACT_PUT_SUCCESS]: (state) => {
        state.contactupdatestatus = "success";
        //    Vue.set(state, "sites", resp);
    },
    [CONTACT_DELETE_SUCCESS]: (state) => {
        state.contactdeletestatus = "success";
        //    Vue.set(state, "sites", resp);
    },
    [CONTACT_DELETE_ERROR]: (state) => {
        state.contactdeletestatus = "error";
        //    Vue.set(state, "sites", resp);
    },
    [CONTACTS_ERROR]: state => {
        state.status = "error";
    },
    [CONTACT_UPDATE_ERROR]: state => {
        state.contactupdatestatus = "error";
    },
    [SITE_CONTACT_ASSIGN]: () => {
        state.contactassignstatus = "loading";
    },
    [SITE_CONTACT_ASSIGN_SUCCESS]: () => {
        state.contactassignstatus = "success";
    },
    [SITE_CONTACT_ASSIGN_ERROR]: () => {
        state.contactassignstatus = "error";
    },
    [SITE_CONTACT_DEASSIGN]: () => {
        state.contactdeassignstatus = "loading";
    },
    [SITE_CONTACT_DEASSIGN_SUCCESS]: () => {
        state.contactdeassignstatus = "success";
    },
    [SITE_CONTACT_DEASSIGN_ERROR]: () => {
        state.contactdeassignstatus = "error";
    },
    [CONTACT_CREATE]: () => {
        state.contactcreatestatus = "loading";
    },
    [CONTACT_CREATE_SUCCESS]: () => {
        state.contactcreatestatus = "success";
    },
    [CONTACT_CREATE_ERROR]: () => {
        state.contactcreatestatus = "error";
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.contacts = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};