<template>
  <b-modal :id="modalID" :ref='modalID' :name="modalID" @shown="resize()" title="Create New Ticket" size="xl" ok-only
           ok-variant="secondary" ok-title="Cancel">
    <section class="wizard">
      <div class="custom-wizard ml-0 mr-0">
        <vue-good-wizard :steps="steps" :onNext="nextClicked" :onBack="backClicked" finalStepLabel="Post">
          <div slot="page1">
            <section>
              <div class="form-row mb-4">
                <label for="tType">Issue Type</label>
                <multiselect id="tType" v-model="tType" :multiple="false" label="name" track-by="value"
                             :close-on-select="true"
                             :clear-on-select="false" placeholder="Select Type" :preserve-search="true"
                             :options="ticketTypes">
                  <template slot="tag">{{ '' }}</template>
                </multiselect>
              </div>
              <section v-if="tType.collectservices">
                <div class="form-row mb-3">
                  <label for="tServices" v-if="tType.value == 'query' || tType.value == 'worksnotify'">Services Impacted (Optional)</label>
                  <label for="tServices" v-else>Services Impacted</label>
                  <multiselect id="tServices" v-model="serviceList" :taggable="true" :multiple="true" label="name"
                               track-by="serviceid"
                               :close-on-select="false" :clear-on-select="false" placeholder="Select Services"
                               :preserve-search="false" :options="services" tagPlaceholder="Sorry, no matches found">
                    <template v-slot:tag="{ option, remove }">
                      <div class="multiselect__tag">
                        <span>{{ option.serviceid }}</span>
                        <i class="multiselect__tag-icon" @click.prevent
                           @mousedown.prevent.stop="remove(option, $event)"/>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </section>
              <section v-if="serviceList.length > 0">
                <b-alert variant="warning" v-for="theservice in checkCurrentOutages()"
                         :key="theservice.service.serviceid" show>
                  <router-link :to="'/event/'+theevent.eventid" class="alert-link font-weight-normal"
                               v-for="theevent in theservice.event" :key="theevent.eventid" @click="hideModal">
                    {{ theservice.service.serviceid }} is affected by {{
                      theevent.type === 3 ? 'unscheduled' : 'scheduled'
                    }} event number #{{ theevent.number }}
                  </router-link>
                </b-alert>
              </section>
            </section>
          </div>
          <div slot="page2">
            <section>
              <section v-if="tType.collecttime && tType.value == 'worksnotify'">
                <div class="form-row">
                  <label for="siteSelection">Where will the Works take place?</label>
                  <multiselect v-model="siteSelection" :multiple="false" required label="sitename" track-by="sitenumber"
                              :close-on-select="true" :clear-on-select="false" placeholder="Select Works Site"
                              :preserve-search="true" :options="siteList" id="siteSelection">
                    <template slot="tag">{{ '' }}</template>
                  </multiselect>
                </div>
                <div class="form-row mb-1 mt-3">
                  <label for="outageStart">What is the window of the planned works?</label>
                </div>
                <div class="form-row mb-3">
                  <div class="col-12 col-md-6 form-group">
                    <label for="worksStartTime">Start Time</label>
                    <b-form-timepicker type="time" id="worksStartTime" v-model="eventStartTime"></b-form-timepicker>
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <label for="worksStartDate">Start Date</label>
                    <b-form-datepicker type="date" id="worksStartDate" v-model="eventStartDate"></b-form-datepicker>
                  </div>
                </div>
                <div class="form-row mb-3">
                  <div class="col-12 col-md-6 form-group">
                    <label for="worksEndTime">End Time</label>
                    <b-form-timepicker type="time" id="worksEndTime" v-model="eventEndTime"></b-form-timepicker>
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <label for="worksEndDate">End Date</label>
                    <b-form-datepicker type="date" id="worksEndDate" v-model="eventEndDate"></b-form-datepicker>
                  </div>
                </div>
              </section>
              <section v-else-if="tType.collecttime && tType.value !== 'worksnotify'">
                <div class="form-row mb-1">
                  <label for="outageStart">When did the {{ tType.issue }} begin?</label>
                </div>
                <div class="form-row mb-3">
                  <div class="col-12 col-md-6 form-group">
                    <label for="outageStart">Time</label>
                    <b-form-timepicker type="time" id="outageStart" v-model="outageTime"></b-form-timepicker>
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <label for="outageDate">Date</label>
                    <b-form-datepicker type="date" id="outageDate" v-model="outageDate"></b-form-datepicker>
                  </div>
                  <div class="col-12 text-right">
                    <b-form-checkbox v-model="outageApproximate">Date/time is approximate</b-form-checkbox>
                  </div>
                </div>
              </section>
              <div class="form-row mb-3" v-if="this.tType.value === 'query'">
                <label for="tDescription">How can the VERNet NOC assist you today?</label>
                <b-form-textarea id="textarea" rows="5" v-model="tDescription"></b-form-textarea>
              </div>
              <div class="form-row mb-3" v-else>
                <label for="tDescription">Can you tell us a bit about the {{ tType.issue }}?</label>
                <b-form-textarea id="textarea" rows="5" v-model="tDescription"></b-form-textarea>
              </div>
            </section>
          </div>
          <div slot="page3" v-if="tType.value !=='query'">
            <section>
              <section v-if="tType.value==='outage'">
                <b-alert variant="success"
                         v-for="theservice in serviceList.filter(service => service.linkstatus.overallstatus==='UP')"
                         :key="theservice.serviceid" show>
                  <i class="fa fa-question-circle-o"/> {{ theservice.serviceid }} looks to be up. Are you sure this is
                  the right service?
                </b-alert>
              </section>
              <section v-if="tType.value==='flapping' || tType.value==='speed'">
                <b-alert variant="success"
                         v-for="theservice in serviceList.filter(service => service.linkstatus.overallstatus==='DOWN')"
                         :key="theservice.serviceid" show>
                  <i class="fa fa-question-circle-o"/> {{ theservice.serviceid }} looks to be down. Are you sure this is
                  the right service?
                </b-alert>
              </section>
              <section v-if="tType.value==='outage'">
                <h3>Outage Troubleshooting Suggestions</h3>
                <p>Have you tried the following to rule out a local issue?
                  <ul>
                    <li>Check your equipment has power at each end?</li>
                    <li>Confirmed Patch Leads are not kinked, or otherwise obstructed or damaged, and the ends are clean?
                    </li>
                    <li>Reseated or replaced the SFP to confirm it is operating correctly?
                    </li>
                    <li>Looped the fibre lead back onto the patch panel to confirm traffic and light are within expected levels?
                    </li>
                    <li>Had someone onsite confirm the equipment is powered and operating normally if remote access is not possible?
                    </li>
                  </ul>
                </p>
              </section>
              <section v-if="tType.value==='flapping'">
                <h3>Flapping Troubleshooting Suggestions</h3>
                <p>Have you tried the following to rule out a local issue?
                  <ul>
                    <li>Check recieved light levels on your equipment to verify they are within the sensitivity limits
                      of your reciever?
                    </li>
                    <li>Confirmed Patch Leads are not kinked, or otherwise obstructed or damaged, and the ends are clean?
                    </li>
                    <li>Reseated or replaced the SFP to confirm it is operating correctly?
                    </li>
                    <li>Had someone onsite confirm the equipment is powered and operating normally if remote access is not possible?
                    </li>
                  </ul>
                </p>
              </section>
              <section v-if="tType.value==='packetloss'">
                <h3>Packet Loss Troubleshooting Suggestions</h3>
                <p>Have you tried the following to rule out a local issue?
                  <ul>
                    <li>Looped the fibre lead back onto the patch panel to confirm traffic and light are within expected levels?
                    </li>
                    <li>Have you tried replacing the optic on both ends to rule out an issue with your equipment?
                    </li>
                  </ul>
                </p>
              </section>
              <section v-if="tType.value==='speed'">
                <h3>Speed Troubleshooting Suggestions</h3>
                <p>To assist with determining the cause of your connection's impacted speed, please perform the following steps;
                  <ul>
                    <li>Set up an iPerf test end-to-end on the service and recording your results.</li>
                    <li>Created a packet capture to see what sort of traffic is moving across the link.</li>
                    <li>Verify your traffic class is set appropriately (we re-class excessive usage of higher traffic
                      classes).
                    </li>
                    <li>When submitting this ticket, please ensure you supply us with details of how you tested the speed, where you tested it, and
                      when to help our NOC team isolate the cause of the fault.
                    </li>
                  </ul>
                </p>
              </section>
              <section v-if="tType.value==='portal'">
                <h3>Portal Troubleshooting Suggestions</h3>
                <p>To help in fixing the issue encountered, please provide the following if possible;
                  <ul>
                    <li>Make note of which page you had problems on.</li>
                    <li>Steps to reproduce the issue</li>
                    <li>Whether you have any errors in your browser console (right-click > Dev Tools > Console).</li>
                    <li>What the returned API payload sent if an API call failed (right-click > Dev Tools > Network).</li>
                  </ul>
                </p>
              </section>
              <section v-if="tType.value==='query'">
                <h3>General Enquiries</h3>
                <p>Naturally, the more detail you can provide us helps us provide a timely resolution to your query.
                  Dates, times, locations, screenshots etc are all very highly regarded!
                </p>
              </section>
              <div class="form-group">
                <label>Troubleshooting steps performed:</label>
                <textarea class="form-control" rows="3" v-model="tTroubleshooting"></textarea>
              </div>
            </section>
          </div>
          <div slot="page4">
            <section>
              <h3>Finish</h3>
              <p>Please review and edit the fields we have generated for you, and then submit your ticket.</p>
              <b-form>
                <b-form-group
                    id="tSubjectLabel"
                    label="Ticket Subject:"
                    label-for="tSubject"
                    description="Please keep this as descriptive as possible"
                >
                  <b-form-input
                      id="tSubject"
                      v-model="tSubject"
                      type="text"
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="tTextLabel"
                    label="Ticket Body:"
                    label-for="tText"
                    description="Feel free to add/edit/remove as you see fit"
                >
                  <b-form-textarea
                      id="tText"
                      v-model="tText"
                      rows="12"
                      required
                  ></b-form-textarea>
                </b-form-group>
              </b-form>
            </section>
          </div>
        </vue-good-wizard>
      </div>
    </section>
  </b-modal>
</template>

<script>
import Snotify, {SnotifyPosition} from 'vue-snotify'
import Multiselect from "vue-multiselect";  
import {GoodWizard} from "vue-good-wizard";
import Vue from "vue";

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.leftTop,
  }
})

const SERVICE_LINKSTATUS_REQUEST = "SERVICE_LINKSTATUS_REQUEST";
const TICKET_CREATE = "TICKET_CREATE";
//const TICKET_COMMENT_UPDATE = "TICKET_COMMENT_UPDATE";

export default {
  name: "newTicketModal",
  props: {
    modalID: String,
    serviceID: String
  },
  components: {
    Multiselect,
    "vue-good-wizard": GoodWizard
  },
  computed: {
    unixStartTime() {
        var StartValue = new Date(this.eventStartDate + " " + this.eventStartTime)
        return StartValue.getTime()/1000
    },
    unixEndTime() {
        var EndValue = new Date(this.eventEndDate + " " + this.eventEndTime)
        return EndValue.getTime()/1000
    },
    siteList() {
      let getSites = this.$store.getters.getPhysicalSitesList
      let sitesList = []

      for (let i = 0; i < getSites.length; i++) {
        let thisSite = {sitenumber: '', sitename: ''}
        thisSite.sitenumber = getSites[i].sitenumber.substring(1)
        thisSite.sitename = getSites[i].sitenumber + ": " + getSites[i].sitename
        sitesList.push(thisSite)
      }

      return sitesList
    },
    services() {
      var getServices = this.$store.getters.getServicesList
      var serviceIDs = []


      for (let i = 0; i < getServices.length; i++) {
        var thisService = {serviceid: '', name: '', linkstatus: {}}
        thisService.serviceid = getServices[i].serviceid
        thisService.name = getServices[i].serviceid + ": " + getServices[i].aendsitename + " to " + getServices[i].bendsitename
        thisService.linkstatus.overallstatus = ''
        serviceIDs.push(thisService)
      }

      return serviceIDs
    },
    steps() {
      if(this.tType.value === "query"){
        return [
          {
            label: "Initial Information",
            slot: "page1"
          },
          {
            label: "Topic of Inquiry",
            slot: "page2"
          },
          {
            label: "Summary",
            slot: "page4",

          }
        ]
      } else if(this.tType.value === "worksnotify"){
        return [
          {
            label: "Initial Information",
            slot: "page1"
          },
          {
            label: "Works Description and Window",
            slot: "page2"
          },
          {
            label: "Summary",
            slot: "page4",

          }
        ]
      } else {
       return [
          {
            label: "Initial Information",
            slot: "page1"
          },
          {
            label: "Issue Description",
            slot: "page2"
          },
          {
            label: "Troubleshooting Steps",
            slot: "page3"
          },
          {
            label: "Summary",
            slot: "page4",

          }
        ]
      }
    }
  },
  data() {
    return {
      ticketTypes: [
        { name: "In-Progress Outage", value: "outage", issue: 'outage', collectservices: true, servicesmandatory: true, collecttime: true },
        { name: "Service Flapping", value: "flapping", issue: 'flapping', collectservices: true, servicesmandatory: true, collecttime: true },
        { name: "Service Slow", value: "speed", issue: 'speed problem', collectservices: true, servicesmandatory: true, collecttime: true },
        { name: "Service Packet Loss", value: "packetloss", issue: 'packet loss', collectservices: true, servicesmandatory: true, collecttime: true },
        { name: "Portal Assistance", value: "portal", issue: 'portal issue', collectservices: false, servicesmandatory: false, collecttime: false },
        { name: "General Query", value: "query", issue: 'query', collectservices: true, servicesmandatory: false, collecttime: false},
        { name: "Notify VERNet of Customer Site Works", value: "worksnotify", issue: 'works notification', collectservices: true, servicesmandatory: false, collecttime: true },
      ],
      serviceList: [],
      diagnosticRequest: false,
      tType:
      {
        name: "",
        value: "",
        issue: '',
        collectservices: false,
        servicesmandatory: false,
        collecttime: false
      },
      tSubject: '',
      tText: '',
      siteSelection: {
        sitenumber: ''
      },
      tTroubleshooting: '',
      outageDate: '',
      outageTime: '',
      eventStartTime: '',
      eventStartDate: '',
      eventEndTime: '',
      eventEndDate: '',
      outageApproximate: false,
      tDescription: '',
/*       steps: [
        {
          label: "Initial Information",
          slot: "page1"
        },
        {
          label: "Issue Description",
          slot: "page2"
        },
        {
          label: "Troubleshooting Steps",
          slot: "page3"
        },
        {
          label: "Summary",
          slot: "page4",

        }
      ] */
    }
  },
  watch: {
    tType() {
      this.tDescription = ""
      this.tTroubleshooting = ""
    },
  },
  methods: {
    hideModal() {
      this.$refs[this.modalID].hide()
    },
    getSite(siteid){
      return this.$store.getters.getSite(siteid)
    },
    nextClicked(currentPage) {
      switch (currentPage) {
        case 0: {
          if (!this.tType.value) {
            return false;
          } else {
              if (this.tType.servicesmandatory === true &&  this.serviceList.length == 0) {
                Vue.prototype.$snotify.warning('Affected services required!'), {
                  timeout: 2000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                }
                return false
              }
              return true
            }
            
        }
        case 1: {
          if(this.tType.value === "query"){
            this.tSubject = this.generateTicketSubject()
            this.tText = this.generateTicketBody()
            return true
          } else if(this.tType.value === "worksnotify"){
            this.tSubject = this.generateTicketSubject()
            this.tText = this.generateTicketBody()

            if(this.siteSelection.sitenumber === ""){
              Vue.prototype.$snotify.warning('Site Required!'), {
                  timeout: 2000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                }
                return false;
            }

            if(!this.eventStartTime || !this.eventStartDate){
              Vue.prototype.$snotify.warning('Works Start Time and Date Required!'), {
                  timeout: 2000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                }
                return false;
            }

            if(!this.eventEndTime || !this.eventEndDate){
              Vue.prototype.$snotify.warning('Works End Time and Date Required!'), {
                  timeout: 2000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                }
                return false;
            }

            return true;
          }

          if (this.tType.servicesmandatory === true) {
            if (this.tType.collecttime === true && (!this.outageDate || !this.outageTime)) { return false }
            if (this.serviceList.length > 0) {
              this.getLinkStatus();
              return true
            } else {
              return false
            }
          } else {
            return true;
          }
        }
        case 2: {
          if(this.tType.value === "query" || this.tType.value === "worksnotify"){
            if(this.tSubject && this.tText) {
            //  window.console.log("Creating Ticket...")
              this.createTicket()
              return true;
            }
            break
          }

          //Generate ticket defaults
          this.tSubject = this.generateTicketSubject()
          this.tText = this.generateTicketBody()
        //  window.console.log("Generating Ticket layout...")
          return true;
        }
        case 3: {
          if(this.tSubject && this.tText) {
            this.createTicket()
            return true;
          }
          break
        }
        default: {
          return true;
        }
      }
    },
    generateTicketSubject() {
      switch (this.tType.value) {
        case 'outage': {
          let serviceString = ''
          if (this.serviceList.length < 4) {
            this.serviceList.forEach((theservice, i) => {
              if (serviceString.length > 0 && i !== (this.serviceList.length - 1)) {
                serviceString = serviceString + ', '
              }
              serviceString = serviceString + this.serviceList[i].serviceid
              if (i === (this.serviceList.length - 2)) {
                serviceString = serviceString + ' and '
              }
            })
          } else {
            serviceString = this.serviceList.length + ' services'
          }
          return (this.serviceList.length > 1 ? serviceString + ' are down' : serviceString + ' is down')
        }
        case 'flapping': {
          let serviceString = ''
          if (this.serviceList.length < 4) {
            this.serviceList.forEach((theservice, i) => {
              if (serviceString.length > 0 && i !== (this.serviceList.length - 1)) {
                serviceString = serviceString + ', '
              }
              serviceString = serviceString + this.serviceList[i].serviceid
              if (i === (this.serviceList.length - 2)) {
                serviceString = serviceString + ' and '
              }
            })
          } else {
            serviceString = this.serviceList.length + ' services'
          }
          return (this.serviceList.length > 1 ? serviceString + ' are flapping' : serviceString + ' is flapping')
        }
        case 'packetloss': {
          let serviceString = ''
          if (this.serviceList.length < 4) {
            this.serviceList.forEach((theservice, i) => {
              if (serviceString.length > 0 && i !== (this.serviceList.length - 1)) {
                serviceString = serviceString + ', '
              }
              serviceString = serviceString + this.serviceList[i].serviceid
              if (i === (this.serviceList.length - 2)) {
                serviceString = serviceString + ' and '
              }
            })
          } else {
            serviceString = this.serviceList.length + ' services'
          }
          return (this.serviceList.length > 1 ? serviceString + ' are experiencing packet loss' : serviceString + ' is experiencing packet loss')
        }
        case 'speed': {
          let serviceString = ''
          if (this.serviceList.length < 4) {
            this.serviceList.forEach((theservice, i) => {
              if (serviceString.length > 0 && i !== (this.serviceList.length - 1)) {
                serviceString += ', '
              }
              serviceString += this.serviceList[i].serviceid
              if (i === (this.serviceList.length - 2)) {
                serviceString += ' and '
              }
            })
          } else {
            serviceString = this.serviceList.length + ' services'
          }
          return (this.serviceList.length > 1 ? serviceString + ' are running slow' : serviceString + ' is running slow')
        }
        case 'portal': {
          return 'Portal query'
        }
        case 'query': {
          let serviceString = ''
          if(this.serviceList.length) {
            if (this.serviceList.length < 4) {
              this.serviceList.forEach((theservice, i) => {
                if (serviceString.length > 0 && i !== (this.serviceList.length - 1)) {
                  serviceString += ', '
                }
                serviceString += this.serviceList[i].serviceid
                if (i === (this.serviceList.length - 2)) {
                  serviceString += ' and '
                }
              })
            } else {
              serviceString = this.serviceList.length + ' services'
            }
          } else {
            return ''
          }
          return 'General query concerning '+serviceString
        }
        case 'worksnotify': {
          return "Planned Works at " + this.siteSelection.sitename
        }
        
      }
    },
    generateTicketBody() {
      let tempBody = ''
      tempBody += "Hi NOC,\n\n"
      tempBody += "I'd like to "
      switch(this.tType.value) {
        case 'outage': {
          tempBody += 'report an outage'
          break;
        }
        case 'flapping': {
          tempBody += 'report flapping'
          break;
        }
        case 'speed': {
          tempBody += 'report a speed problem'
          break;
        }
        case 'packetloss':{
          tempBody += 'report packet loss'
          break;
        }
        case 'query': {
          tempBody += 'make a query; \n\n'
          break;
        }
        case 'portal': {
          tempBody += 'request support with the service portal. \n\n'
          break;
        }
        case 'worksnotify' : {
          tempBody += 'notify VERNet of upcoming onsite works at ' + this.siteSelection.sitename+' \n\n'
          break;
        }
      }

      if(this.tType.collectservices ===true && this.serviceList.length > 0) {
        tempBody += ' for '+this.serviceList.length+" "+(this.serviceList.length>1 ? 'services' : 'service')+":\n"
        this.serviceList.forEach((theService) => {
          tempBody += "- "+theService.name+"\n"
        })
        tempBody += "\n"
      }

      if(this.tType.collecttime ===true && this.tType.value !=="worksnotify") {
        tempBody += 'The '+this.tType.issue+' began '+Vue.moment(this.outageDate+' '+this.outageTime).format("dddd, MMMM Do YYYY, h:mm a")
        if(this.outageApproximate === true) { tempBody+= ' (approximately)'}
        tempBody+= "\n\n"
      } else if(this.tType.collecttime ===true && this.tType.value =="worksnotify") {
        tempBody += 'The planned works will run from '+Vue.moment(this.eventStartDate+' '+this.eventStartTime).format("dddd, MMMM Do YYYY, h:mm a") + ' to '+Vue.moment(this.eventEndDate+' '+this.eventEndTime).format("dddd, MMMM Do YYYY, h:mm a") 
        tempBody+= "\n\n"
      }



      if(this.tDescription) {
        tempBody += this.tDescription+"\n\n"
      }
      if(this.tTroubleshooting) {
        tempBody += "For troubleshooting, so far I have:\n"+this.tTroubleshooting+"\n\n"
      }
      return tempBody
    },
    createTicket() {

      let ticketPayload = {}
 /*   let commentText = ""

      if(this.serviceList.length > 0) {
        commentText = "\n\n\n\nLink Status Information:\n\n" //+ JSON.stringify(this.serviceList)

        for (let i = 0; i < this.serviceList.length; i++) {
          let thisService = "Service ID: " + this.serviceList[i].serviceid 
          + "\nName: " + this.serviceList[i].name 
          + "\nLink Status: " + this.serviceList[i].linkstatus.overallstatus 
          + "\n\n"

          commentText += thisService
        }
      } */

    //  window.console.log("Comment Payload: " + JSON.stringify(commentPayload))

      if(this.serviceList.length > 0){
        let tempServiceArray = []
        this.serviceList.forEach((theService) => {
          tempServiceArray.push(theService.serviceid)
        })
        ticketPayload.services = tempServiceArray
      }
      
      

      let ticketType = ""

      if(this.tType.value === "query"){
        ticketType = "question"
      } else if(this.tType.value === "worksnotify"){
        ticketType = "task"
      } else {
        ticketType = "incident"
      }

      let tempHead = ""

      switch(this.tType.value) {
        case 'outage': {
          tempHead = "[OUTAGE] " + this.tSubject
          break;
        }
        case 'flapping': {
          tempHead = "[FLAPPING] " + this.tSubject
          break;
        }
        case 'speed': {
          tempHead = "[SPEED ISSUE] " + this.tSubject
          break;
        }
        case 'packetloss': {
          tempHead = "[PACKET LOSS] " + this.tSubject
          break;
        }
        case 'query': {
          tempHead = "[QUERY] " + this.tSubject 
          break;
        }
        case 'portal': {
          tempHead = "[PORTAL] " + this.tSubject
          break;
        }
        case 'worksnotify' : {
          tempHead = "[CUSTOMER WORKS] " + this.tSubject + " - " + Vue.moment(this.eventStartDate+' '+this.eventStartTime).format("DD/MM/YY")
          break;
        }
      }

      ticketPayload.subject = tempHead
      ticketPayload.description = this.tText
      ticketPayload.tickettype = ticketType

      if(this.eventStartTime && this.eventStartDate){
        ticketPayload.starttime = this.unixStartTime
      }

      if(this.eventEndTime && this.eventEndDate){
        ticketPayload.endtime = this.unixEndTime
      }

      //window.console.log("Test Payload: " + JSON.stringify(ticketPayload))

/*    if(this.serviceList.length>0) {
        this.tText += "\n\n\n\nLink Status Information:\n" + JSON.stringify(this.serviceList)
      } */
      let tempServiceArray = []
      this.serviceList.forEach((theService) => {
        tempServiceArray.push(theService.serviceid)
      })
      this.$store.dispatch(TICKET_CREATE, {
          ticketPayload
      })
        .then(payload => {
          Vue.prototype.$snotify.success("Ticket #"+payload.payload.ticketid+" created!", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
          });
          this.$emit('TicketCreated', payload.payload.ticketid)

          /* if(this.serviceList.length > 0){
          //  Post Comment with service statuses
            this.$store.dispatch(TICKET_COMMENT_UPDATE, {
              'ticketID': payload.payload.ticketid,
              'payload': {
                'comment': commentText
              }
            })
          } */

          this.$bvModal.hide(this.modalID)
        })
        .catch(err => {
        //  window.console.log("Ticket Creation Error: " + JSON.stringify(err))
          Vue.prototype.$snotify.warning((err.response ? err.response.message : 'An error occurred submitting this ticket. Please try again.'), {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
          });
        })
    },
    getLinkStatus() {
      this.serviceList.forEach((theservice, i) => {
        this.$store.dispatch(SERVICE_LINKSTATUS_REQUEST, {'serviceID': theservice.serviceid})
            .then(resp => {
              if (resp.payload.overallstatus) {
                let newObject = this.serviceList[i]
                newObject.linkstatus = resp.payload
                Vue.set(this.serviceList, i, newObject)
              }
            })
            .catch((resp) => {
              Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving " + theservice.serviceid + " status"), {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
              });
            });
      })
    },
    backClicked() {
      return true;
    },
    resize() {
      let evt = document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    },
    checkCurrentOutages() {
      let returnArray = []
      this.serviceList.forEach((theservice) => {
        if (this.$store.getters.getServiceStatusList(theservice.serviceid).length > 0) {
          returnArray.push({service: theservice, event: this.$store.getters.getServiceStatusList(theservice.serviceid)})
        }
      });
      return returnArray
    }
  },
  beforeMount() {
    if(this.serviceID){
      var getService = this.$store.getters.getService(this.serviceID)
      var thisService = {serviceid: '', name: '', linkstatus: {}}

      thisService.serviceid = getService.serviceid
      thisService.name = getService.serviceid + ": " + getService.aendsitename + " to " + getService.bendsitename
      thisService.linkstatus.overallstatus = ''

      this.serviceList.push(thisService)
    }
  }
}
</script>

<style>
.btn.rounded-circle.text-dark {
  color: #888 !important;
}

.btn.rounded-circle.text-muted {
  color: #444 !important;
}
</style>