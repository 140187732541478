/* eslin/t-disable promise/param-names */

const AUTHTOKEN_REQUEST = "AUTHTOKEN_REQUEST";
const AUTHTOKEN_SUCCESS = "AUTHTOKEN_SUCCESS";
const AUTHTOKEN_ASSUMED = "AUTHTOKEN_ASSUMED";
const AUTHTOKEN_ERROR = "AUTHTOKEN_ERROR";
const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const USERPROFILE_REQUEST = "USERPROFILE_REQUEST";

import Vue from "vue";
import axios from 'axios';
import Snotify, { SnotifyPosition } from 'vue-snotify'
import router from '../../router'

const options = {
    toast: {
        position: SnotifyPosition.leftTop,
    }
}
function showSnotifyWarning(theMessage) {
    Vue.prototype.$snotify.warning(theMessage, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
    });
}
Vue.use(Snotify,options)

const state = {
    token: localStorage.getItem("user-token") || sessionStorage.getItem("user-token") || "",
    status: "",
    hasLoadedOnce: false
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
};

const actions = {
    [AUTHTOKEN_REQUEST]: ({ commit, dispatch, getters }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTHTOKEN_REQUEST);
            commit('addLoading', {handle:'authtoken',name:'Get Auth Token'})
            axios({ url: Vue.prototype.$apiServer + 'login', data: user, method: "POST"})
                .then(resp => {
                    commit('removeLoading', 'authtoken')
                    if(resp.data.result === 'Success') {
                        if(getters.rememberLoading === true) {
                            localStorage.setItem("user-token", resp.data.payload.token);
                        } else {
                            sessionStorage.setItem("user-token", resp.data.payload.token);
                        }
                        Vue.prototype.$token = resp.data.payload.token;
                        // Here set the header of your ajax library to the token value.
                        commit(AUTHTOKEN_SUCCESS, resp.data.payload.token);
                        
                        dispatch(USERPROFILE_REQUEST);
                        resolve(resp.data);
                    } else {
                        commit(AUTHTOKEN_ERROR);
                        showSnotifyWarning(resp.data.message ? resp.data.message : 'Login Failed');
                        reject(resp.data);
                    }
                })
                .catch(err => {
                    commit(AUTHTOKEN_ERROR, err)
                    commit('removeLoading', 'authtoken')
                    localStorage.removeItem("user-token");
                    sessionStorage.removeItem("user-token");
                    Vue.prototype.$token = '';
                    if(err.response.status ===403) {
                        showSnotifyWarning(err.response.data.message ? err.response.data.message : 'Login Failed');
                    } else {
                        showSnotifyWarning(err);
                    }
                    reject(err);
                });
        });
    },
    [AUTHTOKEN_LOGOUT]: ({ commit }) => {
        if(sessionStorage.getItem("user-token")) {
            sessionStorage.removeItem("user-token");
        }
        if(localStorage.getItem("user-token")) {
            localStorage.removeItem("user-token");
        }
        commit(AUTHTOKEN_LOGOUT);
        Vue.prototype.$token = '';
        if(router.currentRoute.path !== '/login'){
            router.push('/login');
        }
        showSnotifyWarning("You have been logged out.");
    }
};

const mutations = {
    [AUTHTOKEN_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTHTOKEN_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.token = resp;
        state.hasLoadedOnce = true;
        axios.defaults.headers.common['X-AUTH-TOKEN'] = resp;
    },
    [AUTHTOKEN_ASSUMED]: (state, resp) => {
        state.status = "assumed";
        state.token = resp;
        axios.defaults.headers.common['X-AUTH-TOKEN'] = resp;
    },
    [AUTHTOKEN_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.token = "";
        delete axios.defaults.headers.common['X-AUTH-TOKEN'];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};