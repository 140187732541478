import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import layout from "../layout";

const AUTHTOKEN_ASSUMED = "AUTHTOKEN_ASSUMED";
const USERPROFILE_REQUEST = "USERPROFILE_REQUEST";
const CUSTOMERPROFILE_REQUEST = "CUSTOMERPROFILE_REQUEST";
const SERVICES_REQUEST = "SERVICES_REQUEST";
const SITES_REQUEST = "SITES_REQUEST";
const SITE_CHECKINS_REQUEST_ALL = "SITE_CHECKINS_REQUEST_ALL";
const USERS_REQUEST = "USERS_REQUEST";
const USERS_ITEMS_NEEDING_ATTENTION = "USERS_ITEMS_NEEDING_ATTENTION";
const EVENTS_REQUEST = "EVENTS_REQUEST";
const ALL_CUSTOMERPROFILES_REQUEST = "ALL_CUSTOMERPROFILES_REQUEST";
const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
const CONTACTS_REQUEST = "CONTACTS_REQUEST";
const FAQ_REQUEST = "FAQ_REQUEST";
const JOBSTATUS_REQUEST = "JOBSTATUS_REQUEST";
//const TICKETS_REQUEST = "TICKETS_REQUEST";

Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
    if (Vue.prototype.$token) {
        store.commit(AUTHTOKEN_ASSUMED, Vue.prototype.$token);
        if (!store.getters.isProfileLoaded || !store.getters.isCustomerProfileLoaded || !store.getters.isServicesListLoaded || !store.getters.isSitesListLoaded) {
            store.dispatch(USERPROFILE_REQUEST).then(() => {

                store.dispatch(USERS_ITEMS_NEEDING_ATTENTION).then(() => {
                })
                .catch((resp) => {
                    Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving items needing attention"), {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                    });
                    next()
                });

                //Load Site Checkins for VERNet staff and Field Technicians
                if(store.getters.getProfile.type === 1 || store.getters.getProfile.type === 2 || store.getters.getProfile.type === 3){
                    store.dispatch(SITE_CHECKINS_REQUEST_ALL).then(() => {
                    })
                    .catch((resp) => {
                        Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving items needing attention"), {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                        });
                        next()
                    });
                }

                if(store.getters.getProfile.type === 1){
                    store.dispatch(JOBSTATUS_REQUEST).then(() => {
                    })
                        .catch((resp) => {
                            Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving AWX Jobs"), {
                                timeout: 2000,
                                showProgressBar: false,
                             closeOnClick: false,
                                pauseOnHover: true,
                            });
                            next()
                        });
                }
            
                store.dispatch(CUSTOMERPROFILE_REQUEST).then(() => {
                    if (store.getters.isAuthenticated) {
                        if (store.getters.getProfile.type === 1 || store.getters.getProfile.type === 4) //If they're a super users, get the users list
                        {
                            store.dispatch(USERS_REQUEST).then(() => {
                                next()
                            })
                                .catch((resp) => {
                                    Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving users"), {
                                        timeout: 2000,
                                        showProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                    });
                                    next()
                                });
                        } else {
                            next()
                        }
                        store.dispatch(ALL_CUSTOMERPROFILES_REQUEST).then(() => {})
                        .catch((resp) => {
                            Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving customer profiles"), {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                            });
                            next()
                        });
                    }
                    //store.dispatch(CONTACTS_REQUEST).then(() => {
                    //store.dispatch(TICKETS_REQUEST).then(() => {

                    if (store.getters.getProfile.type === 2) //If they're a Field Tech, they only need Sites.
                    {
                        store.dispatch(SITES_REQUEST).then(() => { //Sites are last so they can use Services to compare against Events.
                        })
                        .catch((resp) => {
                            Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving sites"), {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                            });
                            next()
                        });
                        store.dispatch(FAQ_REQUEST).then(() => {
                        })
                            .catch((resp) => {
                                Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving FAQ articles"), {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: false,
                                    pauseOnHover: true,
                                });
                                next()
                            });
                    } else{
                        store.dispatch(NOTIFICATIONS_REQUEST).then(() => {
                        })
                            .catch((resp) => {
                                Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving notifications"), {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: false,
                                    pauseOnHover: true,
                                });
                                next()
                            });                   
                    store.dispatch(FAQ_REQUEST).then(() => {
                    })
                        .catch((resp) => {
                            Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving FAQ articles"), {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                            });
                            next()
                        });
                        store.dispatch(EVENTS_REQUEST).then(() => { //Load Events first so they can be evaluated by services and sites.
                            store.dispatch(SERVICES_REQUEST).then(() => { //Get Services next so they can check events for their status.
                                store.dispatch(SITES_REQUEST).then(() => { //Sites are last so they can use Services to compare against Events.
                                })
                                .catch((resp) => {
                                    Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving sites"), {
                                        timeout: 2000,
                                        showProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                    });
                                    next()
                                });
                            })
                            .catch((resp) => {
                                Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving services"), {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: false,
                                    pauseOnHover: true,
                                });
                                next()
                            });  
                        })
                        .catch((resp) => {
                            Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving events"), {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                            });
                            //Couldn't get Events, calling Services and Sites here so they still get loaded.
                            store.dispatch(SERVICES_REQUEST).then(() => {
                            })
                                .catch((resp) => {
                                    Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving services"), {
                                        timeout: 2000,
                                        showProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                    });
                                    next()
                                });
                        store.dispatch(CONTACTS_REQUEST).then(() => {
                            })
                                .catch((resp) => {
                                    Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving contacts"), {
                                        timeout: 2000,
                                        showProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                    });
                                    next()
                                });
                            store.dispatch(SITES_REQUEST).then(() => {
                            })
                                .catch((resp) => {
                                    Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving sites"), {
                                        timeout: 2000,
                                        showProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                    });
                                    next()
                                });
                            next()
                        });
                    }
                })
                .catch((resp) => {
                    Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving all customer profiles"), {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                    });
                    next()
                });
            })
            .catch((resp) => {
                Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred retrieving user profile"), {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                });
                next()
            });
        } else {
            if (store.getters.isAuthenticated) {
                next()
                return
            }
        }
    } else {
        next('/login');
    }
}


export default new Router({
    linkExactActiveClass: "active",
    scrollBehavior: () => ({y: 0}),
    mode: "history",
    base: "/",
    routes: [
        {
            path: "/",
            component: layout,
            children: [
                {
                    path: "",
                    name: "dashboard",
                    title: "Dashboard",
                    beforeEnter: ifAuthenticated,
                    component: () => (store.getters.getProfile.type == 2 ? import("@/pages/tickets/tickets") : import("@/pages/dashboard"))
                    //component: () => import("@/pages/dashboard")
                }
            ]
        },
        {
            path: "/services",
            component: layout,
            children: [
                {
                    path: "",
                    name: "AllServices",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/services")
                },
                {
                    path: "unmanaged",
                    name: "unmanaged",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/unmanaged-services")
                },
                {
                    path: "managed",
                    name: "managed",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/managed-services")
                },
                {
                    path: "vpls-networks",
                    name: "vpls",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/vpls-networks")
                },
                {
                    path: "l3vpn",
                    name: "l3vpn",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/l3vpns")
                },
                {
                    path: "internet",
                    name: "internet",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/internet-services")
                },
                {
                    path: "/service/:serviceid",
                    name: "service",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/service")
                },
            ]
        },
        {
            path: "/jobs",
            component: layout,
            children: [
                {
                    path: "",
                    name: "jobs",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/jobs/jobs")
                },
            ]
        },
        {
            path: "/colocation",
            component: layout,
            children: [
                {
                    path: "rackspaces",
                    name: "rackspaces",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/colocation/rackspaces")
                },
                {
                    path: "crossconnects",
                    name: "crossconnects",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/colocation/crossconnects")
                }
            ]
        },
        {
            path: "/equipment",
            component: layout,
            children: [
                {
                    path: "",
                    name: "equipment",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/equipment/equipment")
                },
                {
                    path: "inventory",
                    name: "inventory",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/equipment/inventory")
                },
                {
                    path: "shelf/:shelfid",
                    name: "shelf",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/equipment/shelf")
                },
            ]
        },
        {
            path: "/customers",
            component: layout,
            children: [
                {
                    path: "",
                    name: "Customers",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/customers/customers")
                },
                {
                    path: "/customers/:customerid",
                    name: "Customer",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/customers/customer")
                },
            ]
        },
        {
            path: "/contacts",
            component: layout,
            children: [
                {
                    path: "",
                    name: "Contacts",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/contacts/contacts")
                },
                {
                    path: "/contact/:contactid",
                    name: "Contact",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/contacts/contact")
                },
            ]
        },
        {
            path: "/documentation",
            component: layout,
            children: [
                {
                    path: "/documentation/sla-reports",
                    name: "sla-reports",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/documentation/sla-reports")
                },
                {
                    path: "/documentation/service-report",
                    name: "service-report",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/documentation/service-report")
                },
                {
                    path: "/documentation/usage-reports",
                    name: "usage-reports",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/documentation/usage-reports")
                },
                {
                    path: "/documentation/changeman-reports",
                    name: "changeman-reports",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/documentation/changeman-reports")
                },
                {
                    path: "/documentation/invoices",
                    name: "invoices",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/documentation/invoices")
                },
            ]
        },
        {
            path: "/tickets",
            component: layout,
            children: [
                {
                    path: "",
                    name: "Tickets",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/tickets/tickets")
                },
                {
                    path: "/ticket/:ticketid",
                    name: "Ticket",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/tickets/tickets")
                }
            ]
        },
        {
            path: "/vpls",
            component: layout,
            children: [
                {
                    path: "/vpls/:vplsid",
                    name: "vpls-service",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/vpls")
                },
            ]
        },
        {
            path: "/l3vpn",
            component: layout,
            children: [
                {
                    path: "/l3vpn/:vpnid",
                    name: "l3vpn-service",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/services/l3vpn")
                },
            ]
        },
        {
            path: "/users",
            component: layout,
            children: [
                {
                    path: "",
                    name: "users",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/users/users")
                },
                {
                    path: "/user/:userid",
                    name: "user",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/users/user")
                },
            ]
        },
        {
            path: "/functions",
            component: layout,
            children: [
                {
                    path: "search-result/:searchParameter",
                    name: "search-result",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/functions/search-result")
                },
                {
                    path: "state-map",
                    name: "state-map",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/functions/state-map")
                }
            ]
        },
        {
            path: "/sites",
            component: layout,
            children: [
                {
                    path: "",
                    name: "sites",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/sites/my-sites")
                },
                {
                    path: "/site/:siteid",
                    name: "site",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/sites/site")
                },
            ]
        },
        {
            path: "/events",
            component: layout,
            children: [
                {
                    path: "",
                    name: "events",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/events/events")
                },
                {
                    path: "scheduled-events",
                    name: "scheduled-events",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/events/scheduled-events")
                },
                {
                    path: "past-events",
                    name: "past-events",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/events/past-events")
                },
                {
                    path: "/event/:eventid",
                    name: "event",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/events/showevent")
                },
            ]
        },
        {
            path: "/login",
            name: "login",
            component: () => import("@/pages/login/login")
        },
        {
            path: "/passwordreset",
            name: "passwordreset",
            component: () => import("@/pages/login/passwordreset")
        },
        {
            path: "/error-pages",
            component: {
                render(c) {
                    return c("router-view");
                }
            },
            children: [
                {
                    path: "error-404",
                    name: "error-404",
                    component: () => import("@/pages/samples/error-pages/error-404")
                },
                {
                    path: "error-500",
                    name: "error-500",
                    component: () => import("@/pages/samples/error-pages/error-500")
                }
            ]
        },
        {
            path: "/general-pages",
            component: layout,
            children: [
                {
                    path: "blank-page",
                    name: "blank-page",
                    component: () => import("@/pages/samples/general-pages/blank-page")
                },
                {
                    path: "customer-faq",
                    name: "customer-faq",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/support/customer-faq")
                },
                {
                    path: "technician-job-creator",
                    name: "technician-job-creator",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/support/technician-job-creator")
                },
                {
                    path: "customer-faq/:faqarticleid",
                    name: "customer-faq-article",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/support/customer-faq")
                },
                {
                    path: "profile",
                    name: "profile",
                    component: () => import("@/pages/samples/general-pages/profile")
                },
                {
                    path: "release-notes",
                    name: "release-notes",
                    beforeEnter: ifAuthenticated,
                    component: () => import("@/pages/support/release-notes")
                },
                {
                    path: "faq-1",
                    name: "faq-1",
                    component: () => import("@/pages/samples/general-pages/faq-1")
                },
                {
                    path: "faq-2",
                    name: "faq-2",
                    component: () => import("@/pages/samples/general-pages/faq-2")
                },
                {
                    path: "news-grid",
                    name: "news-grid",
                    component: () => import("@/pages/samples/general-pages/news-grid")
                },
                {
                    path: "timeline",
                    name: "timeline",
                    component: () => import("@/pages/samples/general-pages/timeline")
                },
                {
                    path: "portfolio",
                    name: "portfolio",
                    component: () => import("@/pages/samples/general-pages/portfolio")
                },
                {
                    path: "user-listing",
                    name: "user-listing",
                    component: () => import("@/pages/samples/general-pages/user-listing")
                }
            ]
        },
        {
            path: "*",
            redirect: "/error-404",
            component: {
                render(c) {
                    return c("router-view");
                }
            },
            children: [
                {
                    path: "error-404",
                    component: () => import("@/pages/samples/error-pages/error-404")
                }
            ]
        }
    ]
});
