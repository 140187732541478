<template>
      <b-modal :id="modalID" :ref='modalID' :name="modalID" title="Current Network Problems" okOnly ok-title="Close" class size="xl">
        <label for="ImpactedSelection" class="mr-2">View impact by: </label><br/><b-form-radio-group
            id="ImpactedSelection"
            :options="options()"
            v-model="selected"
            buttons
            button-variant="outline-primary"
            :name="ImpactedSelection"
            size="sm"
            class="pb-3"
        ></b-form-radio-group>
        <div class="container-fluid pl-0">
            <div class="row" v-if="selected==='1'">
                <serviceComponent @click.native="hideModal" :serviceID="service" v-for="service in serviceIssues" :key="service"></serviceComponent>
            </div>
            <div class="row" v-else>
                <impactedSite @click.native="hideModal" :siteID="site.siteid" v-for="site in impactedSitePage" :key="site.siteid"> </impactedSite>
            </div>
        </div>
    </b-modal>        
</template>

<script>
import serviceComponent from "@/pages/services/components/service";
import impactedSite from "@/pages/sites/components/impacted-site";

export default {
  name: 'modalImpactedServices',
  props: {
    modalID: String
  },
    components:
    {
        serviceComponent,
        impactedSite,
    },
  computed: {
    serviceIssues(){
      var services = this.serviceProblems('A').concat(this.serviceProblems('B')).concat(this.serviceProblems('C')).concat(this.serviceProblems('D')).concat(this.serviceProblems('E')).concat(this.serviceProblems('F')).concat(this.serviceProblems('G')).concat(this.serviceProblems('H'))

      services = services.sort((a, b) => (a.serviceid > b.serviceid ? -1 : 1))
      return services.slice()
    },
      impactedSitePage() {
        var siteList = []

        var AllSites = this.$store.getters.getPhysicalSitesList
        var EventsList = this.$store.getters.getEventsListByType(3)

        for(var i=0; i< AllSites.length; i++)  //For every currently-known ongoing event
        {
            var thisSite = AllSites[i]
            //  serviceList = eventsList[i].filter(service => { return service.slice(-1) === Type })

            for(var y=0; y< EventsList.length; y++) //Go through the services of each event
            {
                var thisEvent = EventsList[y]
                for(var x=0; x< thisEvent.services.length; x++) //Go through the services of each event
                {
                    var thisService = thisEvent.services[x]

                    if((thisService.slice(3,6) === thisSite.sitenumber.slice(1,4)) || (thisService.slice(6,9) === thisSite.sitenumber.slice(1,4))) //If this service matches the type we want, push it into serviceList
                    {
                        if(!siteList.includes(thisSite))  //If service is not already in the serviceList array
                        {
                            siteList.push(thisSite)
                        }
                    }
                }
            }
        }
      return siteList.slice()



    },
    impactedSiteList(){
      var siteList = []

      var AllSites = this.$store.getters.getPhysicalSitesList
      var EventsList = this.$store.getters.getEventsListByType(3)

      for(var i=0; i< AllSites.length; i++)  //For every currently-known ongoing event
      {
          var thisSite = AllSites[i]
          //  serviceList = eventsList[i].filter(service => { return service.slice(-1) === Type })

          for(var y=0; y< EventsList.length; y++) //Go through the services of each event
          {
              var thisEvent = EventsList[y]
              for(var x=0; x< thisEvent.services.length; x++) //Go through the services of each event
              {
                  var thisService = thisEvent.services[x]

                  if((thisService.slice(3,6) === thisSite.sitenumber.slice(1,4)) || (thisService.slice(6,9) === thisSite.sitenumber.slice(1,4))) //If this service matches the type we want, push it into serviceList
                  {
                      if(!siteList.includes(thisSite))  //If service is not already in the serviceList array
                      {
                        siteList.push(thisSite)
                      }
                  }
              }
          }
      }
      siteList = siteList.sort((a, b) => (a.sitenumber > b.sitenumber ? 1 : -1))
      return siteList
    },
  },
  methods: {
    options() {
      return [
      {text: 'Sites ('+this.impactedSitePage.length+')', value: '0'},
      {text: 'Services ('+this.serviceIssues.length+')', value: '1'}
      ]
  },
    servicetype(theType) {
      return this.$serviceTypes.find(service => service.letter === theType);
    },
    serviceProblems(Type)
    {
    let serviceList = this.$store.getters.getImpactedServicesByType(Type)
    return serviceList
    },
    hideModal(){
      this.$refs[this.modalID].hide()
    },
    servicestatus(serviceid) {
      /*         if (this.$store.getters.getCurrentEventsListBySID(serviceID).length > 0) {
              return true;
              } else {
              return false;
              } */
      return this.$store.getters.getServiceStatus(serviceid)
    },
  },
  filters: {
    eventType: function (value) {
      if (value === 1) {
        return "Scheduled Outage"
      } else if (value === 2) {
        return "Scheduled Hazard"
      } else if (value === 3) {
        return "Ongoing Network Outage"
      }
    }
  },
    data: () => ({
        show: false,
        thisService: {},
        toggleProblems: true,
        impactedSiteCount: "",
        selected: 0,
        ImpactedSelection: "",
    })
}
</script>