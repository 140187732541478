const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const INVOICES_REQUEST = "INVOICES_REQUEST";
const INVOICES_ERROR = "INVOICES_ERROR";
const INVOICES_SUCCESS = "INVOICES_SUCCESS";

import Vue from "vue";
import axios from 'axios';

const state = { status: "", invoices: [] };

const getters = {
    getInvoice: (state)=> (invoiceid) => {
       let matchingInvoice = state.invoices.find(invoice => invoice.invoiceid === invoiceid)
      return matchingInvoice
    },
    getInvoices: state => state.invoices,
    getInvoicesStatus: state => state.status

};

const actions = {
    [INVOICES_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(INVOICES_REQUEST);
            commit('addLoading', {handle:'invoices',name:'Load Invoices data'})
            axios({url: Vue.prototype.$apiServer + 'invoices/json', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'invoices')
                    if (resp.data.result === 'Success') {
                        if(Array.isArray(resp.data.payload)) {
                            commit(INVOICES_SUCCESS, resp.data)
                        }
                        resolve(resp.data)
                    } else {
                        commit(INVOICES_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(INVOICES_ERROR);
                    commit('removeLoading', 'invoices')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
};

const mutations = {
    [INVOICES_REQUEST]: state => {
        state.invoicesstatus = "loading";
        Vue.set(state, "invoices", {});
    },
    [INVOICES_SUCCESS]: (state, resp) => {
        state.invoicesstatus = "success";
        resp.payload.forEach(thisInvoice => {
            let thisDate = new Date(parseFloat(thisInvoice.invoicedate)*1000);
            thisInvoice.invoiceyear = thisDate.getFullYear();
            thisInvoice.invoicemonth = thisDate.getMonth() + 1;
            thisInvoice.invoiceday = thisDate.getDate();
        })
        Vue.set(state, "invoices", resp.payload);
    },
    [INVOICES_ERROR]: state => {
    //    state.status = "error";
        Vue.set(state, "invoices", "error");
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.invoices = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};