//import store from "../index";

const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const CUSTOMERPROFILE_REQUEST = "CUSTOMERPROFILE_REQUEST";
const CUSTOMERPROFILE_ERROR = "CUSTOMERPROFILE_ERROR";
const CUSTOMERPROFILE_SUCCESS = "CUSTOMERPROFILE_SUCCESS";
const ALL_CUSTOMERPROFILES_REQUEST = "ALL_CUSTOMERPROFILES_REQUEST";
const ALL_CUSTOMERPROFILES_ERROR = "ALL_CUSTOMERPROFILES_ERROR";
const ALL_CUSTOMERPROFILES_SUCCESS = "ALL_CUSTOMERPROFILES_SUCCESS";
const CUSTOMER_CREATE = "CUSTOMER_CREATE";
const CUSTOMER_CREATE_SUCCESS = "CUSTOMER_CREATE_SUCCESS";
const CUSTOMER_CREATE_ERROR = "CUSTOMER_CREATE_ERROR";
const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
const CUSTOMER_UPDATE_ERROR = "CUSTOMER_UPDATE_ERROR";
import Vue from "vue";
import axios from 'axios';

const state = {status: "", customer: {}, allcustomers: [], allcustomersstatus: ""};

const getters = {
    getMyCustomerProfile: state => state.customer,
    getActiveCustomerProfiles() {
        return (state.allcustomers.filter(customer => {
            return customer.enabled == 1
        }))
    },
    isCustomerProfileLoaded: state => !!state.customer.customername,
    getAllCustomerProfiles: state => state.allcustomers,
    getCustomerProfile: (state)=> (customerid) => {
      return state.allcustomers.find(customer => customer.customerid === customerid)
    },
    getCustomerProfileByCode: (state)=> (customerCode) => {
        return state.allcustomers.find(customer => customer.code === customerCode)
    },
    getCustomerProfileByPin: (state)=> (customerPin) => {
        return state.allcustomers.find(customer => customer.cdrpin == customerPin)
    },
    isAllCustomerProfilesLoaded: state => state.allcustomersstatus
};

const actions = {
    [CUSTOMERPROFILE_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(CUSTOMERPROFILE_REQUEST);
            commit('addLoading', {handle:'customer',name:'Load Customer Profile'})
            axios({url: Vue.prototype.$apiServer + 'customer/me', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'customer')
                    if (resp.data.result === "Success") {
                        commit(CUSTOMERPROFILE_SUCCESS, resp.data);
                        resolve(resp.data)
                    } else {
                        commit(CUSTOMERPROFILE_ERROR);
                        reject(resp.data)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    commit(CUSTOMERPROFILE_ERROR);
                    commit('removeLoading', 'customer')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [ALL_CUSTOMERPROFILES_REQUEST]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(ALL_CUSTOMERPROFILES_REQUEST);
            commit('addLoading', {handle:'allcustomers',name:'Load All Customer Profiles'})
            axios({url: Vue.prototype.$apiServer + 'customers', method: "GET"})
            .then(resp => {
                commit('removeLoading', 'allcustomers')
                if (resp.data.result === "Success") {
                    commit(ALL_CUSTOMERPROFILES_SUCCESS, resp.data);
                    resolve(resp.data)
                } else {
                    commit(ALL_CUSTOMERPROFILES_ERROR);
                    reject(resp.data)
                }
                resolve(resp)
            })
            .catch((err) => {
                commit(ALL_CUSTOMERPROFILES_ERROR);
                commit('removeLoading', 'allcustomers')
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },
    [CUSTOMER_CREATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(CUSTOMER_CREATE);
            commit('addLoading', {handle:'custcreate',name:'Creating New Customer'})
            axios({
                url: Vue.prototype.$apiServer + 'customer',
                method: "POST",
                data: payload
            })
            .then(resp => {
                commit('removeLoading', 'custcreate')
                if(resp.data.result === 'Success') {
                    commit(CUSTOMER_CREATE_SUCCESS, resp.data);
                    dispatch(ALL_CUSTOMERPROFILES_REQUEST);
                    resolve(resp)
                } else {
                    commit(CUSTOMER_CREATE_ERROR);
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit('removeLoading', 'custcreate')
                commit(CUSTOMER_CREATE_ERROR);
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },
    [CUSTOMER_UPDATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(CUSTOMER_UPDATE);
            commit('addLoading', {handle:'custupdate',name:'Update Customer'})
            axios({
                url: Vue.prototype.$apiServer + 'customer/' + payload.customerid,
                method: "PUT",
                data: payload
            })
            .then(resp => {
                commit('removeLoading', 'custupdate')
                if(resp.data.result === 'Success') {
                    commit(CUSTOMER_UPDATE_SUCCESS, resp.data);
                    dispatch(ALL_CUSTOMERPROFILES_REQUEST);
                    resolve(resp)
                } else {
                    commit(CUSTOMER_UPDATE_ERROR);
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit('removeLoading', 'custupdate')
                commit(CUSTOMER_UPDATE_ERROR);
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },
};

const mutations = {
    [CUSTOMERPROFILE_REQUEST]: state => {
        state.status = "loading";
    },
    [CUSTOMERPROFILE_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "customer", resp.payload);
    },
    [CUSTOMERPROFILE_ERROR]: state => {
        state.status = "error";
    },
    [ALL_CUSTOMERPROFILES_REQUEST]: state => {
        state.allcustomersstatus = "loading";
    },
    [ALL_CUSTOMERPROFILES_SUCCESS]: (state, resp) => {
        state.allcustomersstatus = "success";
        Vue.set(state, "allcustomers", resp.payload);
    },
    [ALL_CUSTOMERPROFILES_ERROR]: state => {
        state.allcustomersstatus = "error";
    },
    [CUSTOMERPROFILE_ERROR]: state => {
        state.status = "error";
    },
    [CUSTOMER_CREATE]: state => {
        state.status = "loading";
    },
    [CUSTOMER_CREATE_SUCCESS]: state => {
        state.status = "success";
    },
    [CUSTOMER_CREATE_ERROR]: state => {
        state.status = "error";
    },
    [CUSTOMER_UPDATE]: state => {
        state.status = "loading";
    },
    [CUSTOMER_UPDATE_SUCCESS]: state => {
        state.status = "success";
    },
    [CUSTOMER_UPDATE_ERROR]: state => {
        state.status = "error";
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.customer = {};
        state.allcustomers = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};