<template>
      <b-modal :id="modalID" :ref='modalID' :name="modalID" :title="'Items Needing Attention ('+items.length+' total)'" okOnly ok-title="Close" class size="lg">

        <div class="accordion" role="tablist" v-if="items.length > 0">
          <b-card no-body class="mb-1" v-for="thisItem in items" :key="thisItem.notificationlinknode+thisItem.notificationlinkid">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'accordion-items'+thisItem.notificationlinknode+thisItem.notificationlinkid" variant="primary">
              <i class="fa fa-building mr-2" v-if="thisItem.notificationlinknode==='site'"></i>
                <font-awesome-icon icon="ethernet" v-if="thisItem.notificationlinknode==='service'"/>
                <i class="fa fa-user mr-2" v-if="thisItem.notificationlinknode==='user'"></i>
                {{ thisItem.notificationtitle }}
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-items'+thisItem.notificationlinknode+thisItem.notificationlinkid"  accordion="my-accordion" role="tabpanel">
              <b-card-body fluid class="mt-1 mb-1 p-0 services-card-link " @click="gotoRouter('/'+thisItem.notificationlinknode+'/'+thisItem.notificationlinkid)">
                <b-card-text><div class="row"><div class="col text-center mt-auto mb-auto w-75"><div class="display-5 pl-3">{{thisItem.notificationdescription}}</div></div><div class="col text-right mr-2 w-25"><b-button variant="success"><i class="fa fa-arrow-right mb-2"></i><br /><span class="font-weight-bold">Fix it</span></b-button></div></div></b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="container-fluid p-3 display-4 text-center" v-if="items.length === 0">
          You have no outstanding items needing attention.
        </div>
    </b-modal>        
</template>

<script>
export default {
  name: 'modalItemsNeedingAttention',
  props: {
    modalID: String
  },
  computed: {
    items () {
        return this.$store.getters.getItems
      },
    userprofile() {
      return this.$store.getters.getProfile;
    },
  },
  methods: {
    gotoRouter(routerlink) {
      this.hideModal()
      this.$router.push(routerlink);
    },
    hideModal(){
      this.$refs[this.modalID].hide()
    },
  },
  beforeMount() {
     try {
          var items = this.$store.getters.getItems
         // window.console.log("Items: " + JSON.stringify(items))
          if(this.$store.getters.getItemsSites.length > 0)
          {
            this.options.push({text: 'Sites', value: '0'})
          }
 
          if(this.$store.getters.getItemsServiceNotifications.length > 0)
          {
            this.options.push({text: 'Notifications', value: '2'})
          }

          if(items.users)
          {
            this.options.push({text: 'Users', value: '3'})
          }
        } catch(error) {
        //    window.console.log("Error occurred!")
            return [];
        }
  },
    data: () => ({
        show: false,
        options: [],
    })
}
</script>