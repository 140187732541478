const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const SITES_REQUEST = "SITES_REQUEST";
const SITE_UPDATE = "SITE_UPDATE";
const SITE_PUT_SUCCESS = "SITE_PUT_SUCCESS";
const SITE_UPDATE_ERROR = "SITE_UPDATE_ERROR";
const SITES_SERVICES_REQUEST = "SITES_SERVICES_REQUEST";
const SITES_SERVICES_SUCCESS = "SITES_SERVICES_SUCCESS";
const SITES_SERVICES_ERROR = "SITES_SERVICES_ERROR";
const SITES_SERVICES_FAKE = "SITES_SERVICES_FAKE";
const SITES_ERROR = "SITES_ERROR";
const SITES_SUCCESS = "SITES_SUCCESS";
const USERS_ITEMS_NEEDING_ATTENTION = "USERS_ITEMS_NEEDING_ATTENTION";
const SITE_CREATE = "SITE_CREATE";
const SITE_CREATE_SUCCESS = "SITE_CREATE_SUCCESS";
const SITE_CREATE_ERROR = "SITE_CREATE_ERROR";
const SITE_ACCESS_CREATE= "SITE_ACCESS_CREATE";
const SITE_ACCESS_CREATE_ERROR = "SITE_ACCESS_CREATE_ERROR";
const SITE_ACCESS_CREATE_SUCCESS = "SITE_ACCESS_CREATE_SUCCESS";
const SITE_ACCESS_FORM = "SITE_ACCESS_FORM";
const SITE_ACCESS_FORM_ERROR = "SITE_ACCESS_FORM_ERROR";
const SITE_ACCESS_FORM_SUCCESS = "SITE_ACCESS_FORM_SUCCESS";
const SITE_CHECKINS_REQUEST = "SITE_CHECKINS_REQUEST";
const SITE_CHECKINS_ERROR = "SITE_CHECKINS_ERROR";
const SITE_CHECKINS_SUCCESS = "SITE_CHECKINS_SUCCESS";
const SITE_CHECKIN_USER = "SITE_CHECKIN_USER";
const SITE_CHECKOUT_USER = "SITE_CHECKOUT_USER";
const SITE_CHECKINS_REQUEST_ALL = "SITE_CHECKINS_REQUEST_ALL";
const SITE_CHECKINS_REQUEST_ALL_SUCCESS = "SITE_CHECKINS_REQUEST_ALL_SUCCESS";
const SITE_CHECKINS_REQUEST_ALL_ERROR = "SITE_CHECKINS_REQUEST_ALL_ERROR";

import Vue from "vue";
import axios from 'axios';

const state = { status: "", siteservicesstatus: "", sites: [], sitecheckins: [] };

const getters = {
    getSitesList: state => state.sites,
    isSitesListLoaded: state => !!state.sites,
    getSite: (state) => (siteId) => {
        return (state.sites === null ? {} : state.sites.find(site => site.siteid === siteId))
    },
    getChildSites: (state) => (siteID) => {
        return (state.sites === null ? [] : state.sites.filter(site => {
            return (site.parentsite === siteID && site.sitetype == 1)
        }))
    },
    getSiteByNumber: (state) => (siteNumber) => {
        return (state.sites === null ? {} : state.sites.find(site => site.sitenumber === siteNumber))
    },
    getPhysicalSitesList: (state) => {
        return (state.sites === null ? [] : state.sites.filter(site => site.sitetype == 1))
    },
    getVLANSites: (state) => {
    //    window.console.log("Found Sites: " + JSON.stringify(state.sites.filter(site => site.sitenumber.substring(0, 2) == 'S9')) + " -TEST")
        return (state.sites === null ? [] : state.sites.filter(site => site.sitetype == 2))
    },
    getPhysicalSitesByCustomerUUIDList: (state) => (customerUUID) => {
        return (state.sites === null ? [] : state.sites.filter(site => {
            return (site.customerid === customerUUID && site.sitetype == 1)
        }))
    },
    getAllSitesByCustomerUUIDList: (state) => (customerUUID) => {
        return (state.sites === null ? [] : state.sites.filter(site => {
            return site.customerid === customerUUID && site.sitenumber.substring(0, 2)
        }))
    },
    searchSites: (state) => searchParam => {
        var searchTerm = new RegExp(searchParam, "i")
        return (state.sites === null ? [] : state.sites.filter((site => searchTerm.test(site.sitenumber) || searchTerm.test(site.sitename) || searchTerm.test(site.street))))
    },

    getSiteCheckins: (state) => siteid => {
        return (state.sitecheckins === null ? {} : state.sitecheckins.filter(checkin => checkin.siteid === siteid))
    },
    getUserCheckins: (state) => userid => {
        return (state.sitecheckins === null ? {} : state.sitecheckins.filter(checkin => checkin.userid === userid))
    }
};

const actions = {
    [SITES_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(SITES_REQUEST);
            commit('addLoading', {handle:'sites',name:'Load Sites'})
            axios({url: Vue.prototype.$apiServer + 'sites', method: "GET"})
            .then(resp => {
                commit('removeLoading', 'sites')
                if(resp.data.result === 'Success') {
                    commit(SITES_SUCCESS, resp.data)
                    resolve(resp.data)
                } else {
                    commit(SITES_ERROR);
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit(SITES_ERROR);
                commit('removeLoading', 'sites')
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },
    [SITE_UPDATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(SITE_UPDATE);
            commit('addLoading', {handle:'siteupdate',name:'Update Site'})
            axios({
                url: Vue.prototype.$apiServer + 'site/' + payload.siteID,
                method: "PUT",
                data: payload.thisSite
            })
                .then(resp => {
                    commit('removeLoading', 'siteupdate')
                    if(resp.data.result === 'Success') {
                        commit(SITE_PUT_SUCCESS, resp.data);
                        dispatch(SITES_REQUEST);
                        resolve(resp)
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                    } else {
                        commit(SITE_UPDATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit('removeLoading', 'siteupdate')
                    commit(SITE_UPDATE_ERROR);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITE_ACCESS_CREATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(SITE_ACCESS_CREATE);
            commit('addLoading', {handle:'siteaccess',name:'Submitting Site Access'})
            axios({
                url: Vue.prototype.$apiServer + 'site/' + payload.siteID + "/access",
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'siteaccess')
                    if(resp.data.result === 'Success') {
                        commit(SITE_ACCESS_CREATE_SUCCESS);
                        resolve(resp)
                    } else {
                        commit(SITE_ACCESS_CREATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit('removeLoading', 'siteaccess')
                    commit(SITE_UPDATE_ERROR);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITE_ACCESS_FORM]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(SITE_ACCESS_FORM);
            commit('addLoading', {handle:'siteaccessform',name:'Submitting Site Access Form'})
            axios({
                url: Vue.prototype.$apiServer + 'site/' + payload.siteID + "/access/form",
                method: "PUT",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'siteaccessform')
                //    window.console.log("resp:" + JSON.stringify(resp))
                    if(resp.data.result === 'Success') {
                        commit(SITE_ACCESS_FORM_SUCCESS);
                        resolve(resp)
                    } else {
                        commit(SITE_ACCESS_FORM_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit('removeLoading', 'siteaccessform')
                    commit(SITE_ACCESS_FORM_ERROR);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITES_SERVICES_REQUEST]: ({ commit, dispatch }, siteid) => {
        return new Promise((resolve, reject) => {
            commit(SITES_SERVICES_REQUEST);
            commit('addLoading', {handle:'siteservices',name:'Load Site Services'})
            axios({url: Vue.prototype.$apiServer + 'site/' + siteid + '/services', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'siteservices')
                    if(resp.data.result === 'Success') {
                        commit(SITES_SERVICES_SUCCESS, {"siteid": siteid, "response": resp.data.payload.services});
                        dispatch(SITES_REQUEST);
                        resolve(resp)
                    } else {
                        commit(SITES_SERVICES_ERROR);
                        reject(resp.data)
                    }
                })
                .catch(err => {
                    //showSnotifyWarning("An error occurred while getting a list of services terminating at this site." + err);
                    // if resp is unauthorized, logout, to
                    commit('removeLoading', 'siteservices')
                    commit(SITES_SERVICES_ERROR);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITE_CREATE]: ({ commit, dispatch }, payload) => {
    //    window.console.log("New Site Payload: " + JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            commit(SITE_CREATE);
            commit('addLoading', {handle:'sitecreate',name:'Creating New Site'})
            axios({
                url: Vue.prototype.$apiServer + 'site',
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'sitecreate')
                    if(resp.data.result === 'Success') {
                        commit(SITE_CREATE_SUCCESS, resp.data);
                        dispatch(SITES_REQUEST);
                        resolve(resp)
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                    } else {
                        commit(SITE_CREATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit('removeLoading', 'sitecreate')
                    commit(SITE_CREATE_ERROR);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITE_CHECKINS_REQUEST_ALL]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(SITE_CHECKINS_REQUEST_ALL);
            commit('addLoading', {handle:'sitesallcheckins',name:'Loading Site Check-Ins'})
            axios({url: Vue.prototype.$apiServer + 'checkins', method: "GET"})
            .then(resp => {
                commit('removeLoading', 'sitesallcheckins')
                if(resp.data.result === 'Success') {
                    commit(SITE_CHECKINS_REQUEST_ALL_SUCCESS, resp.data)
                    resolve(resp.data)
                } else {
                    commit(SITE_CHECKINS_REQUEST_ALL_ERROR);
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit(SITE_CHECKINS_REQUEST_ALL_ERROR);
                commit('removeLoading', 'sitesallcheckins')
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },
    [SITE_CHECKINS_REQUEST]: ({commit, dispatch}, siteid) => {
        return new Promise((resolve, reject) => {
            commit(SITE_CHECKINS_REQUEST);
            commit('addLoading', {handle: 'getcheckins'+siteid, name: 'Loading Site Checkin List'})
            axios({url: Vue.prototype.$apiServer + 'site/'+siteid + '/checkins', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'getcheckins'+siteid)
                    if (resp.data.result === 'Success') {
                        commit(SITE_CHECKINS_SUCCESS)
                        resolve(resp.data.payload)
                    } else {
                        commit(SITE_CHECKINS_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SITE_CHECKINS_ERROR);
                    commit('removeLoading', 'getcheckins'+siteid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITE_CHECKIN_USER]: ({ commit, dispatch }, payload) => {
        //    window.console.log("New Site Payload: " + JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            commit(SITE_CHECKIN_USER);
            commit('addLoading', {handle:'checkinSite',name:'Checking into Site'})
            axios({
                url: Vue.prototype.$apiServer + 'site/' + payload.siteid + '/checkin',
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'checkinSite')
                    if(resp.data.result === 'Success') {
                        commit(SITE_CHECKINS_SUCCESS, resp.data);
                        resolve(resp)
                    } else {
                        commit(SITE_CHECKINS_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit('removeLoading', 'checkinSite')
                    commit(SITE_CHECKINS_ERROR);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SITE_CHECKOUT_USER]: ({ commit, dispatch }, payload) => {
        //    window.console.log("New Site Payload: " + JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            commit(SITE_CHECKOUT_USER);
            commit('addLoading', {handle:'checkoutSite',name:'Checking out of Site'})
            axios({
                url: Vue.prototype.$apiServer + 'site/' + payload.siteid + '/checkout',
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'checkoutSite')
                    if(resp.data.result === 'Success') {
                        commit(SITE_CHECKINS_SUCCESS, resp.data);
                        resolve(resp)
                    } else {
                        commit(SITE_CHECKINS_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit('removeLoading', 'checkoutSite')
                    commit(SITE_CHECKINS_ERROR);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },


    
};

const mutations = {
    [SITES_REQUEST]: state => {
        state.status = "loading";
    },
    [SITE_UPDATE]: state => {
        state.status = "updating";
    },
    [SITE_CREATE]: state => {
        state.sitecreatestatus = "creating";
    },
    [SITE_CREATE_SUCCESS]: state => {
        state.sitecreatestatus = "success";
    },
    [SITE_CREATE_ERROR]: state => {
        state.sitecreatestatus = "error";
    },
    [SITE_CREATE]: state => {
        state.sitecreatestatus = "creating";
    },
    [SITES_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "sites", resp.payload);
    },
    [SITE_CHECKINS_REQUEST_ALL]: state => {
        state.status = "loading";
    },
    [SITE_CHECKINS_REQUEST_ALL_ERROR]: state => {
        state.status = "error";
    },
    [SITE_CHECKINS_REQUEST_ALL_SUCCESS]: (state, resp) => {
        state.status = "loading";
        Vue.set(state, "sitecheckins", resp.payload);
    },
    [SITE_PUT_SUCCESS]: (state) => {
        state.siteupdatestatus = "success";
    //    Vue.set(state, "sites", resp);
    },
    [SITES_ERROR]: state => {
        state.status = "error";
    },
    [SITE_ACCESS_CREATE]: state => {
        state.siteaccessstatus = "creating";
    },
    [SITE_ACCESS_CREATE_ERROR]: state => {
        state.siteaccessstatus = "error";
    },
    [SITE_ACCESS_CREATE_SUCCESS]: state => {
        state.siteaccessstatus = "success";
    },
    [SITE_ACCESS_FORM]: state => {
        state.siteaccessformstatus = "creating";
    },
    [SITE_ACCESS_FORM_ERROR]: state => {
        state.siteaccessformstatus = "error";
    },
    [SITE_ACCESS_FORM_SUCCESS]: state => {
        state.siteaccessformstatus = "success";
    },
    [SITE_UPDATE_ERROR]: state => {
        state.siteupdatestatus = "error";
    },
    [SITES_SERVICES_REQUEST]: state => {
        state.siteservicesstatus = "loading";
    },
    [SITES_SERVICES_SUCCESS]: (state, payload) => {
        state.siteservicesstatus = "success";
        Vue.set(state.sites.find(site => site.siteid === payload.siteid), "services", payload.response);
    },
    [SITES_SERVICES_FAKE]: (state, payload) => {
        state.siteservicesstatus = "success";
        Vue.set(state.sites.find(site => site.siteid === payload.siteid), "services", payload.response);
    },
    [SITES_SERVICES_ERROR]: (state) => {
        state.siteservicesstatus = "error";
    },
    [SITE_CHECKINS_REQUEST]: state => {
        state.status = "loading";
    },
    [SITE_CHECKINS_ERROR]: state => {
        state.status = "error";
    },
    [SITE_CHECKINS_SUCCESS]: state => {
        state.status = "success";
    },
    [SITE_CHECKIN_USER]: state =>
    {
        state.status = "loading";
    }, 
    [SITE_CHECKOUT_USER]: state =>
    {
        state.status = "loading";
    },    
    [AUTHTOKEN_LOGOUT]: state => {
        state.sites = {};
    }
};
    
export default {
    state,
    getters,
    actions,
    mutations
};