//import store from "../index";

const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const JOBSTATUS_REQUEST = "JOBSTATUS_REQUEST";
const JOBSTATUS_ERROR = "JOBSTATUS_ERROR";
const JOBSTATUS_SUCCESS = "JOBSTATUS_SUCCESS";
const JOB_WATCH_ADD = "JOB_WATCH_ADD";
const JOB_WATCH_REMOVE = "JOB_WATCH_REMOVE";
const JOB_RETRIEVE_REQUEST = "JOB_RETRIEVE_REQUEST";
const JOB_RETRIEVE_SUCCESS = "JOB_RETRIEVE_SUCCESS";
const JOB_RETRIEVE_ERROR = "JOB_RETRIEVE_ERROR";

import Vue from "vue";
import axios from "axios";

const state = { status: "", jobs: [], watchjobs: [], running: false, interval: null };

const getters = {
  getJob: (state) => (jobid) => state.jobs.find(job => job.id == jobid),
  getJobs: (state) => state.jobs,
  getShelfJobs: (state) => (shelfid) =>  state.jobs.filter(job => job.hosts && job.hosts.find(hostid => hostid == shelfid)),
  getOngoingJobs:(state) => state.jobs.filter(job => job.ended == false),
  getWatchedJobs: (state) => state.jobs.filter((job) => state.watchjobs.includes(job.id)),
};

const actions = {
  [JOBSTATUS_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(JOBSTATUS_REQUEST);
      axios({ url: Vue.prototype.$apiServer + "awx/jobs", method: "GET" })
        .then((resp) => {
          if (resp.data.result === "Success") {
            commit(JOBSTATUS_SUCCESS, resp.data)
            
            if(state.jobs.filter((job) => job.ended === false).length == 0) { //No active jobs being watched, stop interval polling.
              state.running = false;
              clearInterval(state.interval);
            } else { //Scan for jobs not already in watchedjobs list, append them.
              let newJobs = state.jobs.filter(job => job.ended === false && state.watchjobs.filter(watchedJob => watchedJob == job.id).length == 0)
              for(var thisJob of newJobs){
                if(!state.watchjobs.find(watchedJob => watchedJob.id == thisJob.id)){
                  dispatch(JOB_WATCH_ADD, thisJob.id)
                }
              }
            }

            resolve(resp.data);
          } else {
            commit(JOBSTATUS_ERROR);
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
        //  window.console.log("Error: " + JSON.stringify(err))
          commit(JOBSTATUS_ERROR);
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [JOB_RETRIEVE_REQUEST]: ({commit, dispatch}, jobID) => {
    return new Promise((resolve, reject) => {
      commit(JOB_RETRIEVE_REQUEST);
      commit('addLoading', {handle: 'job' + jobID, name: 'Retrieving Job ' + jobID})
      axios({url: Vue.prototype.$apiServer + 'awx/job/' + jobID , method: "GET"})
        .then(resp => {
            commit('removeLoading', 'job' + jobID)
            if (resp.data.result === 'Success' && !resp.data.message) {
                commit(JOB_RETRIEVE_SUCCESS, {'resp': resp.data, 'serviceid': jobID})
                resolve(resp.data)
            } else {
                commit(JOB_RETRIEVE_ERROR)
                reject(resp.data)
            }
        })
        .catch((err) => {
            commit(JOB_RETRIEVE_ERROR);
            commit('removeLoading', 'job' + jobID)
            // if resp is unauthorized, logout, to
            if (err.response.status === 403) {
                dispatch(AUTHTOKEN_LOGOUT);
            }
            reject(err.response.data)
        });
      })
   },

  [JOB_WATCH_ADD]: ({ commit, dispatch }, jobid) => {
    return new Promise((resolve) => {
      commit(JOB_WATCH_ADD, jobid);
      dispatch(JOBSTATUS_REQUEST);
      if (state.watchjobs.length > 0 && !state.running) {
        state.running = true;
        state.interval = setInterval(() => {
          dispatch(JOBSTATUS_REQUEST);
        }, 10000);
      }
      resolve();
    });
  },
  [JOB_WATCH_REMOVE]: ({ commit }, jobid) => {
    return new Promise((resolve) => {
      commit(JOB_WATCH_REMOVE, jobid);
      resolve();
    });
  },
};


const mutations = {
  [JOBSTATUS_REQUEST]: (state) => {
    state.status = "loading";
  },
  [JOBSTATUS_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "jobs", resp.payload);
  },
  [JOBSTATUS_ERROR]: (state) => {
    state.status = "error";
  },
  [JOB_RETRIEVE_REQUEST]: (state) => {
    state.status = "loading";
  },
  [JOB_RETRIEVE_SUCCESS]: (state) => {
    state.status = "success";
  },
  [JOB_RETRIEVE_ERROR]: (state) => {
    state.status = "error";
  },
  [JOB_WATCH_ADD]: (state, jobid) => {
    state.watchjobs.push(jobid);
    state.watchjobs = [...new Set(state.watchjobs)];
  },
  [JOB_WATCH_REMOVE]: (state, jobid) => {
    state.watchjobs = state.watchjobs.filter((job) => job !== jobid);
    if (state.watchjobs.length === 0) {
      state.running = false;
      //Cancel the timer we started in JOB_WATCH_ADD
      clearInterval(state.interval);
    }
  },
  [AUTHTOKEN_LOGOUT]: (state) => {
    state.jobs = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
