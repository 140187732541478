<template>
        <div  class="services-card card col-12 p-0" :class="activeCheck">
          <div class="row container-fluid services-card-row">
            <div v-if='statusCheck() && service.status == 0' class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-luminous'">
              <div class="content text-center">{{ servicetype(service.type).type }}</div> <!--If Service is disabled-->
            </div>
            <div v-else-if='statusCheck() && service.status == 2' class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-planning'">
              <div class="content text-center">{{ servicetype(service.type).type }}</div> <!--If Service is planned but not active yet-->
            </div>
            <div class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-warning'" v-else-if="serviceStatus.state === 1 && !showColour">
              <div class="content text-center">{{ servicetype(service.type).type }}</div>
            </div>
            <div class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-warning'" v-else-if="serviceStatus.state === 2 && !showColour">
              <div class="content text-center">{{ servicetype(service.type).type }}</div>
            </div>
            <div class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-danger'" v-else-if="serviceStatus.state === 3 && !showColour">
              <div class="content text-center">{{ servicetype(service.type).type }}</div>
            </div>
            <div class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-'+servicetype(service.type).color" v-else>
              <div class="content text-center">{{ servicetype(service.type).type }}</div>
            </div>

            <router-link :to="'/service/'+service.serviceid" class="services-card-link col-7 col-xl-4 serviceid p-2">
              <div class="content pl-xl-2">
                <h4 class="text-muted">{{ service.serviceid }}</h4>
                <div class="text-muted">{{ service.description }}</div>
                <span class="d-xl-none text-small">
                  <br>{{ service.aendsitename }} =&gt; {{ service.bendsitename }}
                </span>
              </div>
            </router-link>
            <router-link :to="'/site/'+service.aendsiteid" class="col-2 site container-fluid d-none d-xl-inline services-card-link">
              <div class="row sitenumber">
                <h4 class="text-muted">{{ service.aendsitenumber }}</h4>
              </div>
              <div class="row sitename text-muted">{{ service.aendsitename }}</div>
            </router-link>
            
            <router-link :to="'/vpls/'+service.bendsiteid" v-if="service.type == 'F' || service.type == 'W'" class="col-2 site container-fluid d-none d-xl-inline services-card-link">
              <div class="row sitenumber">
                <h4 class="text-muted">{{ service.bendsitenumber }}</h4>
              </div>
              <div class="row sitename text-muted">{{ service.bendsitename }}</div>
            </router-link>
            <router-link :to="'/site/'+service.bendsiteid" v-else class="col-2 site container-fluid d-none d-xl-inline services-card-link">
              <div class="row sitenumber">
                <h4 class="text-muted">{{ service.bendsitenumber }}</h4>
              </div>
              <div class="row sitename text-muted">{{ service.bendsitename }}</div>
            </router-link>

            <router-link :to="'/event/'+serviceStatus.events[0].eventid" class=" col-2 services-card-link site container-fluid d-none d-xl-inline" v-if="serviceStatus.state > 0">
              <div class="row sitenumber">
                <h4 class="text-muted">Status</h4>
              </div>
              <div class="row sitename text-warning" v-if="serviceStatus.state == 1">
                Undergoing Scheduled Works #{{ serviceStatus.events[0].number }}
              </div>
              <div class="row sitename text-info" v-else-if="serviceStatus.state == 2">
                Within Hazard Window #{{ serviceStatus.events[0].number }}
              </div>
              <div class="row sitename text-danger" v-else-if="serviceStatus.state == 3">
                Affected by Network Outage #{{ serviceStatus.events[0].number }}
              </div>
            </router-link>

            <div class="col-2 site container-fluid d-none d-xl-inline" v-else>
              <div class="row sitenumber">
                <h4 class="text-muted">Status</h4>
              </div> 
              <div class="row sitename text-luminous" v-if = "statusCheck() && service.status == 0"> <!--Check if service.status exists first for VERNet users as Customers don't see disabled services-->
              Disabled
              </div>
              <div class="row sitename" v-bind:class="'text-' + serviceUsageColour" v-else-if="getUsage && !allowUtilisation"> <!--Customers don't see service status. if no events found, assume active-->
                  {{ serviceUsageText}} <!--Customers experience a usage check for if service is nearing/at capacity-->
              </div>
              <div class="row sitename" v-bind:class="'text-' + serviceUsageColour" v-else-if="getUsage && allowUtilisation"> <!--Customers don't see service status. if no events found, assume active-->
                  {{ serviceUsageText}} <i class="pl-2 pr-1 ti-arrow-up d-inline"></i> {{ atobUsage }}% / {{ btoaUsage  }}% <i class="pl-1 ti-arrow-down d-inline"></i> <!--Customers experience a usage check for if service is nearing/at capacity-->
              </div>
              <div class="row sitename text-planning" v-else-if="!getUsage && allowUtilisation">
                  <button @click.prevent="checkUsage" class="btn btn-sm btn-success" v-if="!isChecking">Check Usage</button>
                  <button @click.prevent="checkUsage" class="btn btn-sm btn-success" disabled v-else>Checking...</button>
              </div>
              <div class="row sitename text-success" v-else-if="statusCheck() && service.status == 1">
                Online
              </div>
              <div class="row sitename text-planning" v-else-if="statusCheck() && service.status == 2">
                In Planning
              </div>
              <div class="row sitename text-success" v-else-if = "!statusCheck()"> <!--No status? Show Online -->
                Online
              </div> 
              <div class="row sitename text-luminous" v-else>
                Disabled
              </div>

            </div>
          </div>
        </div>

</template>



<script>
  const SERVICE_UTILISATION_REQUEST = "SERVICE_UTILISATION_REQUEST";

    export default {
        name: 'serviceComponent',
        props: {
          serviceID: String,
          showColour: Boolean,
          loadUtilisation: {
            type: Boolean,
            default: false
          },
          allowUtilisation: {
            //Describes whether a user is allowed to request the utilization of a service manually.
            type: Boolean,
            default: false
          }
        }, 
        computed:{
          service(){
            try {
              return this.$store.getters.getService(this.serviceID);
            } 
            catch (error){
              return this.items;
            }
          },
          activeCheck(){
            if(this.service && Object.keys(this.service).includes("status")){
              if(this.service.status == 0){
                return "inactiveElement"
              } else{
                return "activeElement"
              }
            } else {
              return "activeElement"
            }
          },
          serviceUsageColour(){
            if(this.atobUsage > 80 || this.btoaUsage > 80){ //Above usage threshold, return red
              return "danger"
            } else{ //Normal utilization, return green
              return "success"
            }
          },
          serviceUsageText(){
            if(this.atobUsage > 95 || this.btoaUsage > 95){
              return "At Capacity"
            } else if(this.atobUsage > 80 || this.btoaUsage > 80){
              return "Nearing Capacity"
            } else{
              return "Online"
            }
          }
        },
        data () {
          return {
            serviceStatus: {
              state: 0,
              events: []
            },
            atobUsage: 0,
            btoaUsage: 0,
            isChecking: false,
            getUsage: false
          }
        },
        beforeMount(){
          if(this.statusCheck()){
            if(this.service.status == 0){
              let disabledStatus = {
                state: 0,
                events: []
              }
              this.serviceStatus = disabledStatus
            } else {
              this.serviceStatus = this.$store.getters.getServiceStatus(this.serviceID)
            }
          } else {
            this.serviceStatus = this.$store.getters.getServiceStatus(this.serviceID)
            if (this.loadUtilisation) {
                this.checkUsage()
            }
          }
        },
        methods:{
          servicetype(theType) {
            return this.$serviceTypes.find(service => service.letter === theType);
          },
          statusCheck(){ //Check if service object has "status" in it
            return Object.keys(this.service).includes("status")
          },
          checkUsage(){
            if(this.service.type !== "A" && this.service.status !== 0){ //Generate the average utilization for the past hour
              let payload = {
                serviceid: this.serviceID,
                timespan: 1
              }
              this.isChecking = true
              this.$store.dispatch(SERVICE_UTILISATION_REQUEST, payload)
              .then(resp => {
                  let servicePayload = resp.payload
                  if(servicePayload && Object.keys(servicePayload).includes("atob") && servicePayload.atob !== null){   
                    this.atobUsage = Math.round(servicePayload.atob.reduce((a, b) => a + b.percentage, 0) / servicePayload.atob.length)
                    this.getUsage = true
                  }
                  if(servicePayload && Object.keys(servicePayload).includes("btoa")&& servicePayload.btoa !== null){ 
                    this.btoaUsage = Math.round(servicePayload.btoa.reduce((b, a) => b + a.percentage, 0) / servicePayload.btoa.length)
                    this.getUsage = true
                  }
                  this.isChecking = false
                }).catch(() => {
                  this.atobUsage = 0
                  this.btoaUsage = 0
                  this.isChecking = false
                  this.getUsage = false
              });
            } else {
              //If unmanaged, set usage to 0
              this.atobUsage = 0
              this.btoaUsage = 0
              this.isChecking = false
              this.getUsage = false
            }
          }
        }
   }
</script>