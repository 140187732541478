<template>
  <b-modal :id="modalID" :ref="modalID" @shown="getEquipment()" title="Update Shelf in Database" hide-footer size="md">
    <form id="serviceadd">
      <div class="mb-3">
        <section>
            <div class="form-row">
              <label for="serviceTypeSelection">Select Shelf:</label>
              <div class="col-12 col-md-12 form-group">
                <multiselect v-model="selectedEquipment" :multiple="false" label="equipmentname" track-by="equipmentid"
                                :close-on-select="true" :clear-on-select="false" placeholder="Select Shelf"
                                :preserve-search="true" :options="equipmentList" id="equipmentListSelection">
                    <template slot="tag">{{ '' }}</template>
                </multiselect>
              </div>
            </div>
            <div class="form-row mt-3 mb-3" v-if="checkState == 1">
              <h4>Checking {{ selectedEquipment.equipmentname }}...</h4>
            </div>
            <div class="form-row mt-3 mb-3" v-if="updateRequired && checkState == 0">
              <h4>{{ selectedEquipment.equipmentname }} could not be checked! <i :class="'ml-2 icon-close text-danger'"/></h4>
            </div>
            <div class="form-row mt-3 mb-3" v-if="updateRequired && checkState == 2">
              <h4>{{ selectedEquipment.equipmentname }} needs to be updated, please see email sent to NOC inbox <i :class="'ml-2 icon-close text-danger'"/></h4>
            </div>
            <div class="form-row mt-3 mb-3" v-if="!updateRequired && checkState == 2">
              <h4>{{ selectedEquipment.equipmentname }} is up-to-date! <i :class="'ml-2 icon-check text-success'"/></h4>
            </div>
            <div class="form-row mt-3 mb-3" v-if="checkState == 3">
              <h4>Updating {{ selectedEquipment.equipmentname }}...</h4>
            </div>
            <div class="form-row mt-3 mb-3" v-if="!updateRequired && checkState == 4">
              <h4>{{ selectedEquipment.equipmentname }} updated successfully! <i :class="'ml-2 icon-check text-success'"/></h4>
            </div>
            <div class="form-row mt-3 mb-3" v-if="updateRequired && checkState == 4">
              <h4>{{ selectedEquipment.equipmentname }} failed to update! <i :class="'ml-2 icon-close text-danger'"/></h4>
            </div>



        </section>
        <div class="row mt-1">
          <div class="col-12 text-right">
            <button class="btn btn-light text-right p-3 mr-2" @click="hideModal" type="reset">
              <i class="mdi-cancel mdi pr-2"/>Cancel
            </button>
            <button class="btn btn-light text-right p-3 mr-2"  @click.prevent="checkEquipment()">
              <i class="fa fa-spin fa-spinner"  v-if="checkState == 1"/>
              <i class="fa fa-download " v-else/> Check Shelf
            </button>
            <button class="btn btn-success text-right p-3" @click.prevent="updateEquipment()">
              <i class="fa fa-spin fa-spinner"  v-if="checkState == 3"/>
              <i class="icon-plus " v-else/> Update Shelf
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Snotify, {SnotifyPosition} from 'vue-snotify'
import Vue from "vue"
import Multiselect from "vue-multiselect";
import axios from "axios";

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.leftTop,
  }
})



export default {
  name: "updateEquipmentModal",
  props: {
    modalID: String
  },
  components:
      {
        Multiselect
      },
  data() {
    return {
      equipmentList: [],
      selectedEquipment: {},
      equipmentChanges: [],
      checkState: 0,
      loadingShelf: false,
      retrievedEquipment: false,
      updateRequired: false,
    }
  },
  computed: {

  },
  methods: {
    hideModal() {
        this.equipmentList = []
        this.equipmentChnges = []
        this.selectedEquipment= {}
        this.$refs[this.modalID].hide()
    },
    getEquipment(){
        axios({url: Vue.prototype.$apiServer + 'shelves', method: "GET"})
          .then(resp => {
            this.equipmentList = resp.data.payload
          } )
          .catch(() => {
       //     window.console.log("Error:  Unable to load equipment!")
          });
    },
    checkEquipment(){
      this.updateRequired = false
      this.checkState = 1
      //  window.console.log("Checking Equipment...")
    //    window.console.log("Updating Equiment...\n" + JSON.stringify(this.selectedEquipment))
    //    window.console.log("URL : " + Vue.prototype.$apiServer + 'tools/equipment/' + this.selectedEquipment.equipmentid + '/check')
        let payload = {}
        payload.equipmentid = this.selectedEquipment.equipmentid
        axios({url: Vue.prototype.$apiServer + 'equipment/' + this.selectedEquipment.equipmentid + '/check', method: "POST", data: payload})
          .then(resp => {
            this.checkState = 2
          //  window.console.log("Response: " + JSON.stringify(resp))
            if(resp.data.message == "Equipment needs to be updated, please see email sent to NOC inbox"){
              this.updateRequired = true
            }

            if(resp.data.result !== "Success") {
                Vue.prototype.$snotify.warning((resp.data.message ? resp.data.message : this.selectedEquipment.equipmentname + " did not load successfuly"), {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                });             
            }
          } )
          .catch(() => {
            this.checkState = 0
            this.updateRequired = true
          //  window.console.log("Error: " + JSON.stringify(err))
            Vue.prototype.$snotify.warning((this.selectedEquipment.equipmentname + " did not update successfuly"), {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
            });
          });
    },
    updateEquipment(){
      this.updateRequired = false
    //  window.console.log("Updating Equipment...")
    //    window.console.log("Updating Equiment...\n" + JSON.stringify(this.selectedEquipment))
    //    window.console.log("URL : " + Vue.prototype.$apiServer + 'tools/equipment/' + this.selectedEquipment.equipmentid + '/check')
      this.checkState = 3
      let payload = {}
      payload.equipmentid = this.selectedEquipment.equipmentid
      axios({url: Vue.prototype.$apiServer + 'equipment/' + this.selectedEquipment.equipmentid + '/update', method: "POST", data: payload})
        .then(resp => {
      //    window.console.log("Equipment Loaded: " + JSON.stringify(resp))
          this.checkState = 4
          if(resp.data.result == "Success"){
              this.updateRequired = false
              Vue.prototype.$snotify.success((this.selectedEquipment.equipmentname + " updated successfully!"), {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              });
          } else {
              this.updateRequired = true
              Vue.prototype.$snotify.warning((this.selectedEquipment.equipmentname + " did not update successfuly"), {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              });
          }
        } )
        .catch(() => {
          this.checkState = 4
          this.updateRequired = true
      //    window.console.log("Error: " + JSON.stringify(err))
          Vue.prototype.$snotify.warning((this.selectedEquipment.equipmentname + " did not update successfuly"), {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
          });
        });
    }
  },

}
</script>