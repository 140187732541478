const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const USERPROFILE_REQUEST = "USERPROFILE_REQUEST";
const USERPROFILE_ERROR = "USERPROFILE_ERROR";
const USERPROFILE_UPDATE = "USERPROFILE_UPDATE";
const USERPROFILE_UPDATE_ERROR = "USERPROFILE_UPDATE_ERROR";
const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";
const USERPROFILE_PUT_SUCCESS = "USERPROFILE_PUT_SUCCESS";
const USERPROFILE_DELETE = "USERPROFILE_DELETE";
const USERPROFILE_DELETE_SUCCESS = "USERPROFILE_DELETE_SUCCESS";
const USERPROFILE_DELETE_ERROR = "USERPROFILE_DELETE_ERROR";
const USERPROFILE_CREATE = "USERPROFILE_CREATE";
const USERPROFILE_CREATE_SUCCESS = "USERPROFILE_CREATE_SUCCESS";
const USERPROFILE_CREATE_ERROR = "USERPROFILE_CREATE_ERROR";
const USERPROFILE_SWAP_CUSTOMER = "USERPROFILE_SWAP_CUSTOMER";
const USERPROFILE_SWAP_CUSTOMER_SUCCESS = "USERPROFILE_SWAP_CUSTOMER_SUCCESS";
const USERPROFILE_SWAP_CUSTOMER_ERROR = "USERPROFILE_SWAP_CUSTOMER_ERROR";
const USERS_ITEMS_NEEDING_ATTENTION = "USERS_ITEMS_NEEDING_ATTENTION";
const USERPROFILE_RESET_PASSWORD = "USERPROFILE_RESET_PASSWORD";
const USERPROFILE_RESET_PASSWORD_SUCCESS = "USERPROFILE_RESET_PASSWORD_SUCCESS";
const USERPROFILE_RESET_PASSWORD_ERROR = "USERPROFILE_RESET_PASSWORD_ERROR";
const USERPROFILE_CHANGE_PASSWORD = "USERPROFILE_CHANGE_PASSWORD";
const USERPROFILE_CHANGE_PASSWORD_SUCCESS = "USERPROFILE_CHANGE_PASSWORD_SUCCESS";
const USERPROFILE_CHANGE_PASSWORD_ERROR = "USERPROFILE_CHANGE_PASSWORD_ERROR";

import Vue from "vue";
import axios from 'axios';

const state = { status: "", user: {} };

const getters = {
    getProfile: state => state.user,
    isProfileLoaded: state => !!state.user.firstname
};

const actions = {
    [USERPROFILE_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(USERPROFILE_REQUEST);
            commit('addLoading', {handle:'userprofile',name:'Load User Profile'})
            axios({url: Vue.prototype.$apiServer + 'user/me', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'userprofile')
                    if (resp.data.result === "Success") {
                        commit(USERPROFILE_SUCCESS, resp.data);
                        resolve(resp.data)
                    } else {
                        commit(USERPROFILE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(USERPROFILE_ERROR);
                    commit('removeLoading', 'userprofile')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [USERPROFILE_UPDATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(USERPROFILE_UPDATE);
           // window.console.log("Updating")
            commit('addLoading', {handle:'userupdate' + payload.userid,name:'Update User Profile'})
            axios({
                url: Vue.prototype.$apiServer + 'user/' + payload.userid,
                method: "PUT",
                data: payload
            })
                .then(resp => {
                //    window.console.log("Response: " + JSON.stringify(resp))
                    commit('removeLoading', 'userupdate' + payload.userid)
                    if(resp.data.result === 'Success') {
                        commit(USERPROFILE_PUT_SUCCESS, payload);
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                        resolve(resp.data)
                    } else {
                        commit(USERPROFILE_UPDATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
               //     window.console.log("Error: " + JSON.stringify(err))
                    commit(USERPROFILE_UPDATE_ERROR);
                    commit('removeLoading', 'userupdate' + payload.userid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [USERPROFILE_DELETE]: ({ commit, dispatch }, userid) => {
        return new Promise((resolve, reject) => {
            commit(USERPROFILE_DELETE);
            commit('addLoading', {handle:'userdelete' + userid,name:'Delete User Profile'})
            axios({
                url: Vue.prototype.$apiServer + 'user/' + userid,
                method: "DELETE"
            })
                .then(resp => {
                    commit('removeLoading', 'userdelete' + userid)
                    if(resp.data.result === 'Success') {
                        commit(USERPROFILE_DELETE_SUCCESS, userid);
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                        resolve(resp.data)
                    } else {
                        commit(USERPROFILE_DELETE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(USERPROFILE_DELETE_ERROR);
                    commit('removeLoading', 'userdelete' + userid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [USERPROFILE_CREATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(USERPROFILE_CREATE);
            commit('addLoading', {handle:'usercreate',name:'Create User Profile'})
            axios({
                url: Vue.prototype.$apiServer + 'user',
                method: "POST",
                data: payload
            })
                .then(resp => {
                  //  window.console.log("User Creation Response: " + JSON.stringify(resp))
                    commit('removeLoading', 'usercreate')
                    if(resp.data.result === 'Success') {
                        commit(USERPROFILE_CREATE_SUCCESS);
                        dispatch(USERS_ITEMS_NEEDING_ATTENTION)
                        resolve(resp.data)
                    } else {
                        commit(USERPROFILE_CREATE_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(USERPROFILE_CREATE_ERROR);
                    commit('removeLoading', 'usercreate')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [USERPROFILE_RESET_PASSWORD]: ({ commit }, uemail) => {
    //    window.console.log("Resetting Password...")
    //    window.console.log("Reset Payload: " + JSON.stringify(uemail))
        return new Promise((resolve, reject) => {
            commit(USERPROFILE_RESET_PASSWORD);
            commit('addLoading', {handle:'resetpassword',name:'Resetting User Password'})
            axios({
                url: Vue.prototype.$apiServer + '/user/passwordreset/request',
                method: "POST",
                data: uemail
            })
                .then(resp => {
                //    window.console.log("Resetting Password Payload Sent...")
                    commit('removeLoading', 'resetpassword')
                    if(resp.data.result === 'Success') {
                        commit(USERPROFILE_RESET_PASSWORD_SUCCESS);
                        resolve(resp.data)
                    } else {
                        commit(USERPROFILE_RESET_PASSWORD_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(USERPROFILE_RESET_PASSWORD_ERROR);
                    commit('removeLoading', 'resetpassword')
                    reject(err.response.data)
                });
        })
    },
    [USERPROFILE_CHANGE_PASSWORD]: ({ commit }, uemail) => {
    //    window.console.log("Changing Password...")
    //    window.console.log("New Password Payload: " + JSON.stringify(uemail))
        return new Promise((resolve, reject) => {
            commit(USERPROFILE_CHANGE_PASSWORD);
            commit('addLoading', {handle:'changepassword',name:'Changing User Password'})
            axios({
                url: Vue.prototype.$apiServer + '/user/passwordreset/submit',
                method: "POST",
                data: uemail
            })
                .then(resp => {
                //    window.console.log("Resetting Password Payload Sent...")
                    commit('removeLoading', 'changepassword')
                    if(resp.data.result === 'Success') {
                        commit(USERPROFILE_CHANGE_PASSWORD_SUCCESS);
                        resolve(resp.data)
                    } else {
                        commit(USERPROFILE_CHANGE_PASSWORD_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(USERPROFILE_CHANGE_PASSWORD_ERROR);
                    commit('removeLoading', 'changepassword')
                    reject(err.response.data)
                });
        })
    },
    [USERPROFILE_SWAP_CUSTOMER]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(USERPROFILE_SWAP_CUSTOMER);
            commit('addLoading', {handle:'customerchange' + payload.customerid, name:'Swap Customer Chameleon'})
            axios({
                url: Vue.prototype.$apiServer + "user/impersonate",
                method: "POST",
                data: payload
            })
                .then(resp => {
                    commit('removeLoading', 'customerchange' + payload.customerid)
                    if(resp.data.result === 'Success') {
                        commit(USERPROFILE_SWAP_CUSTOMER_SUCCESS, payload);
                        resolve(resp.data)
                    } else {
                        commit(USERPROFILE_SWAP_CUSTOMER_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(USERPROFILE_SWAP_CUSTOMER_ERROR);
                    commit('removeLoading', 'customerchange' + payload.customerid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
};

const mutations = {
    [USERPROFILE_REQUEST]: state => {
        state.status = "loading";
    },
    [USERPROFILE_UPDATE]: state => {
        state.status = "updating";
    },
    [USERPROFILE_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "user", resp.payload);
    },
    [USERPROFILE_PUT_SUCCESS]: (state) => {
        state.status = "success";
    },
    [USERPROFILE_ERROR]: state => {
        state.status = "error";
    },
    [USERPROFILE_UPDATE_ERROR]: state => {
        state.status = "error";
    },
    [USERPROFILE_DELETE]: () => {
        state.deleteprofilestatus = "loading";
    },
    [USERPROFILE_DELETE_SUCCESS]: () => {
        state.deleteprofilestatus = "success";
    },
    [USERPROFILE_DELETE_ERROR]: () => {
        state.deleteprofilestatus = "error";
    },
    [USERPROFILE_CREATE]: () => {
        state.createprofilestatus = "loading";
    },
    [USERPROFILE_CREATE_SUCCESS]: () => {
        state.createprofilestatus = "success";
    },
    [USERPROFILE_CREATE_ERROR]: () => {
        state.createprofilestatus = "error";
    },
    [USERPROFILE_SWAP_CUSTOMER]: () => {
        state.customerswapstatus = "loading";
    },
    [USERPROFILE_SWAP_CUSTOMER_SUCCESS]: () => {
        state.customerswapstatus = "success";
    },
    [USERPROFILE_SWAP_CUSTOMER_ERROR]: () => {
        state.customerswapstatus = "error";
    },
    [USERPROFILE_RESET_PASSWORD]: () => {
        state.passwordresetstatus = "error";
    },
    [USERPROFILE_RESET_PASSWORD_ERROR]: () => {
        state.passwordresetstatus = "error";
    },
    [USERPROFILE_RESET_PASSWORD_SUCCESS]: () => {
        state.passwordresetstatus = "success";
    },
    [USERPROFILE_CHANGE_PASSWORD]: () => {
        state.passwordchangestatus = "error";
    },
    [USERPROFILE_CHANGE_PASSWORD_ERROR]: () => {
        state.passwordchangestatus = "error";
    },
    [USERPROFILE_CHANGE_PASSWORD_SUCCESS]: () => {
        state.passwordchangestatus = "success";
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.user = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};