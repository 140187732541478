<template>
  <section class="main-view">
    <div class="container-scroller">
      <b-overlay z-index="1200" :show="stillLoading" rounded="sm" bgColor="#1e1e2f" blur="5px" no-center>
        <template v-slot:overlay>
          <div class="text-center vh-100 vw-100">
            <div class="mt-5">
          <h1 class="text-muted pt-5">Loading</h1>
          <div class="grid-margin stretch-card">
            <div class="dot-opacity-loader">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <b-list-group class="w-50 mx-auto">
            <transition name="fade" v-for="loadItem in loadingItems()" v-bind:key="loadItem.handle" >
              <b-list-group-item variant="primary"><i class="fa fa-refresh fa-spin"></i> {{ loadItem.name }}</b-list-group-item>
            </transition>
          </b-list-group>
            </div>
          </div>
        </template>
        <b-overlay z-index="1200" :show="storeEmpty && !stillLoading" rounded="sm" bgColor="#1e1e2f" blur="5px">
          <template v-slot:overlay>
            <div class="text-center">
              <h1 class="text-muted">:(</h1>
              <h3 class="text-muted">Something went wrong</h3>
              <b-button href="/" variant="primary" class="mt-2">Reload</b-button><br />
              <b-button @click="userLogout()" variant="primary" class="mt-2">Logout</b-button>
            </div>
          </template>
          <Header />
          <div class="container-fluid page-body-wrapper">
            <Sidebar />
            <div class="main-panel">
              <div class="content-wrapper">

                <router-view></router-view>
              </div>
              <!-- content wrapper ends -->
              <Footer />
            </div>
            <!-- main panel ends -->
          </div>
        </b-overlay>
      </b-overlay>

      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import Header from "./partials/Header";
import Sidebar from "./partials/Sidebar";
import Footer from "./partials/Footer";
const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";

export default {
  name: "layout",
  components: {
    Header,
    Sidebar,
    Footer,
  },
  computed: {
    stillLoading () {
      if (!this.loadingItems().length > 0) {
        return false
      } else {
        return true
      }
    },
    storeEmpty () {
      if (!this.$store.getters.isCustomerProfileLoaded || (this.$store.getters.getServicesList === null ) || !this.$store.getters.isProfileLoaded) {

        if(this.$store.getters.getProfile.customerid!=='59327dfd-1cdd-11ec-9551-005056a4b5f8') { return true } else { return false }
      } else {
        return false
      }
    }
  },
  methods: {
    userLogout: function () {
      this.$store.dispatch(AUTHTOKEN_LOGOUT).then(() => {
        if (this.$route.path != '/login') {
            this.$router.push("/login");
        }
      })
    },
    loadingItems() {
      return this.$store.getters.getLoading;
    }
  }
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>