<template>
  <b-modal :id="modalID" :ref='modalID' :name="modalID" @shown="resize()" title="Create New Event" size="xl" ok-only
           ok-variant="secondary" ok-title="Cancel">
    <section class="wizard">
      <div class="custom-wizard ml-0 mr-0">
          
        <vue-good-wizard :steps="steps" :onNext="nextClicked" :onBack="backClicked">
          <div slot="page1">
            <section>
                <div class="form-row mb-4">
                    <div class="form-col mb-4 col-3">
                      <div class="form-row">
                        <label for="eType">Issue Type</label>
                        <multiselect id="eType" v-model="eType" :multiple="false" label="name" track-by="value"
                                        :close-on-select="true"
                                        :clear-on-select="false" placeholder="Select Type" :preserve-search="true"
                                        :options="eventTypes">
                            <template slot="tag">{{ '' }}</template>
                        </multiselect>
                      </div>
                      <div class="form-row mt-3">
                        <label for="eType">SLA Classification</label>
                        <multiselect id="eType" v-model="slaTypeSelection" :multiple="false" label="name" track-by="value"
                                        :close-on-select="true"
                                        :clear-on-select="false" placeholder="Select Type" :preserve-search="true"
                                        :options="slaTypes">
                            <template slot="tag">{{ '' }}</template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="form-col ml-3 mb-4 col-4">
                      <div class="form-row">
                        <label for="eType">Event Title</label>
                        <b-form-input type="text" v-model="eTitle"></b-form-input>
                      </div>
                      <div class="form-row mt-2">
                        <label for="eType">Event Prefill</label>
                        <multiselect id="eType" v-model="eventPrefillSelection" :multiple="false" label="name" track-by="description"
                                        :close-on-select="true"
                                        :clear-on-select="false" placeholder="Select Type" :preserve-search="true"
                                        :options="eventPrefill">
                            <template slot="tag">{{ '' }}</template>
                        </multiselect>
                      </div>
                      
                    </div>
                    <div class="form-col mb-4 ml-3 col-3">
                        <label for="eType">Zendesk Ticket</label>
                        <b-form-input class="col-5" type="number" min="0" max="9999" v-model="zTicket" :number="true" ></b-form-input>
                      
                    </div>
                    <div class="form-col mb-4 col-2">
                        <button type="button" class="btn btn-primary btn-icon-text btn-sm" @click.prevent="getTicket()"> 
                            <i class="ti-pencil btn-icon-append mr-2 ml-0"></i><span>Prefill Event</span>
                        </button>
                    </div>
                    <div class="form-col">
                      <switches label="Send Notification?" v-model="sendNotification" theme="bootstrap" color="primary" class="pl-2 pr-2"></switches>
                    </div>
                    
                </div>
                <div class="form-row mb-3">
                    <div class="col-6 col-md-6 form-group">
                    <label for="eventStartTime">Start Time</label>
                    <b-form-timepicker type="time" id="eventStartTime" v-model="eventStartTime"></b-form-timepicker>
                    </div>
                    <div class="col-6 col-md-6 form-group">
                    <label for="eventStartDate">Start Date</label>
                    <b-form-datepicker type="date" id="eventStartDate" v-model="eventStartDate"></b-form-datepicker>
                    </div>
                </div>
                <div class="form-row mb-3" v-if="eType.value !== 3">
                    <div class="col-12 col-md-6 form-group">
                    <label for="eventEndTime">End Time</label>
                    <b-form-timepicker type="time" id="eventEndTime" v-model="eventEndTime"></b-form-timepicker>
                    </div>
                    <div class="col-12 col-md-6 form-group">
                    <label for="eventEndDate">End Date</label>
                    <b-form-datepicker type="date" id="eventEndDate" v-model="eventEndDate"></b-form-datepicker>
                    </div>
                </div>
                <div class="form-row mb-3">
                  <div class="col-12 col-md-6 form-group">
                  <label for="eDescription">Event Description</label>
                <textarea class="form-control" rows="5" id="eventDescription" v-model="eDescription" ></textarea>
                  </div>
                  <div class="col-12 col-md-6 form-group">
                  <label for="eServices">Event Services 
                    <div class="text-danger" v-for="error in serviceErrors" :key="error">
                      <br>{{ error }}
                    </div>
                  </label>
                  <multiselect id="eServices" v-model="serviceList" :taggable="true" :multiple="true" label="name"
                          track-by="serviceid"
                          :close-on-select="false" :clear-on-select="false" placeholder="Select Services"
                          :preserve-search="false" :options="services" tagPlaceholder="Sorry, no matches found">
                      <template v-slot:tag="{ option, remove }">
                      <div class="multiselect__tag">
                          <span>{{ option.serviceid }}</span>
                          <i class="multiselect__tag-icon" @click.prevent
                          @mousedown.prevent.stop="remove(option, $event)"/>
                      </div>
                      </template>
                  </multiselect>
                  <div class="pt-2">
                  <label for="ePriority">Event Priority</label>
                    <multiselect id="ePriority" v-model="ePriority" :multiple="false" label="name" track-by="value"
                                    :close-on-select="true"
                                    :clear-on-select="false" placeholder="Select Type" :preserve-search="true"
                                    :options="eventPriority">
                        <template slot="tag">{{ '' }}</template>
                    </multiselect>
                  </div>
                    </div>
                  </div>
                  <div class="form-row mb-12">
                    <div class="col-12 col-md-6 form-group">
                      <label for="eDescription">Event Initiator</label>
                      <b-form-input type="text" v-model="initiator"></b-form-input>
                    </div>
                    <div class="col-12 col-md-6 form-group">
                      <label for="eDescription">Event Approver</label>
                      <b-form-input type="text" v-model="approver"></b-form-input>
                    </div>
                  </div>
                
              <section v-if="eType.collectservices">
                <div class="form-row mb-3">
                  <label for="tServices">Services Impacted</label>
                  <multiselect id="tServices" v-model="serviceList" :taggable="true" :multiple="true" label="name"
                               track-by="serviceid"
                               :close-on-select="false" :clear-on-select="false" placeholder="Select Services"
                               :preserve-search="false" :options="services" tagPlaceholder="Sorry, no matches found">
                    <template v-slot:tag="{ option, remove }">
                      <div class="multiselect__tag">
                        <span>{{ option.serviceid }}</span>
                        <i class="multiselect__tag-icon" @click.prevent
                           @mousedown.prevent.stop="remove(option, $event)"/>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </section>
              <section v-if="serviceList.length > 0">
                <b-alert variant="warning" v-for="theservice in checkCurrentevents()"
                         :key="theservice.service.serviceid" show>
                  <router-link :to="'/event/'+theevent.eventid" class="alert-link font-weight-normal"
                               v-for="theevent in theservice.event" :key="theevent.eventid" @click="hideModal">
                    {{ theservice.service.serviceid }} is affected by {{
                      theevent.type === 3 ? 'unscheduled' : 'scheduled'
                    }} event number #{{ theevent.number }}
                  </router-link>
                </b-alert>
              </section>
            </section>
          </div>
          <div slot="page2">
            <section>
                <h4 class="pb-3">Customer Tickets Preflight Check:</h4>
                <div role="tablist" class="accordion" v-if="eventCustomers.length > 0">
                 <b-card no-body v-for="thiscustomer in eventCustomers" :key="thiscustomer.customerid">
                   <b-card-header header-tag="header" role="tab">
                      <a href="javascript:void(0);" v-b-toggle="'custAccord' + thiscustomer.customerid">{{customerName(thiscustomer.customer).customername}} 
                        <i v-if="thiscustomer.skipped.length > 0" class="pl-3 menu-icon fa fa-exclamation-triangle"></i> <!--Add an alert icon if there's skipped services-->
                      </a>
                    </b-card-header>
                    <b-collapse :id="'custAccord' + thiscustomer.customerid" accordion="my-accordion" role="tabpanel">
                     <b-card-body>
                        <div class="row ">
                            <div class="col-md-3">
                            Services: 
                            <br><br>
                            </div>
                            <div class="col-md-3" v-if="thiscustomer.services && thiscustomer.services.length > 0" >
                                <div class="row" v-for="thisService in thiscustomer.services" :key="thisService">
                                {{thisService}}
                                </div>
                            </div>
                        </div>
                        <hr color="gray" v-if="thiscustomer.services && thiscustomer.services.length > 0" />
                        <div class="row pt-3" v-if="thiscustomer.skipped.length > 0">
                            <div class="col-md-3">
                            Skipped Services: 
                            <br><br>
                            </div>
                            <div class="col-md-3">
                                <div class="row" color="primary" v-for="thisService in thiscustomer.skipped" :key="thisService">
                                {{thisService}}  <i class="pl-3 menu-icon fa fa-exclamation-triangle"></i>
                                </div>
                            </div>
                        </div>
                        <hr color="gray" v-if="thiscustomer.skipped.length > 0" />
                        <br>
                      <div  v-if="thiscustomer.tickets">
                          <div class="row">
                              <div class="col-md-3">
                              Recipient:
                              <br><br>
                              </div>
                              <div class="col-md-9">
                                  {{thiscustomer.tickets.requester}}
                              </div>
                          </div>
                          <div class="row"  v-if="thiscustomer.tickets.ccs">
                              <div class="col-md-3">
                              CC's:
                              </div>
                              <div class="col-md-9">
                                <div v-for="thisCC in thiscustomer.tickets.ccs" :key="thisCC">
                                {{thisCC}}<br>
                                </div>
                              </div>
                          </div>
                          <hr color="gray" v-if="thiscustomer.tickets.ccs" />
                          <div class="row"> 
                              <div class="col-md-3">
                              Email Message:
                              <br><br>
                              </div>
                              <div class="col-md-9">
                                  <p v-html="htmlComment(thiscustomer.tickets.htmlcomment)"></p>
                              </div>
                          </div>
                          <hr color="gray"/>
                        </div>
                        <div v-if="thiscustomer.sms">
                            <div v-for="thisSMS in thiscustomer.sms" :key="thisSMS.smsto">
                                <div class="row">
                                    <div class="col-md-3">
                                        SMS Recipient:
                                        <br><br>
                                    </div>
                                    <div class="col-md-9" >
                                        {{thisSMS.recepient}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        Receiving Number:
                                        <br><br>
                                    </div>
                                    <div class="col-md-9" >
                                        {{thisSMS.smsto}}
                                    </div>
                                </div>
                                <div class="row pb-3">
                                    <div class="col-md-3">
                                        Service ID's:
                                        <br><br>
                                    </div>
                                    <div class="col-md-9" >
                                        <div class="row" v-for="thisService in thisSMS.serviceid" :key="thisService">
                                            {{thisService}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                    SMS Text:
                                    <br><br>
                                    </div>
                                    <div class="col-md-9">
                                    {{thisSMS.message}}
                                    </div>
                                </div>
                                <hr color="gray"/>
                            </div>
                        </div>                    
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
            </section>
          </div>
          <div slot="page3">
              <section>
                <h4 class="pb-3">Customer Tickets Dispatched:</h4>
                <div role="tablist" class="accordion" v-if="eventCustomers.length > 0">
                 <b-card no-body v-for="thiscustomer in eventCustomers" :key="thiscustomer.customerid">
                   <b-card-header header-tag="header" role="tab">
                      <a href="javascript:void(0);" v-b-toggle="'custAccord' + thiscustomer.customerid">{{customerName(thiscustomer.customer).customername}}</a>
                    </b-card-header>
                    <b-collapse :id="'custAccord' + thiscustomer.customerid" accordion="my-accordion" role="tabpanel">
                     <b-card-body>
                        <div class="row ">
                            <div class="col-md-3">
                            Services: 
                            <br><br>
                            </div>
                            <div class="col-md-3" v-if="thiscustomer.services && thiscustomer.services.length > 0" >
                                <div class="row" v-for="thisService in thiscustomer.services" :key="thisService">
                                {{thisService}}
                                </div>
                            </div>
                        </div>
                        <hr color="gray" v-if="thiscustomer.services && thiscustomer.services.length > 0" />
                        <div class="row pt-3" v-if="thiscustomer.skipped.length > 0">
                            <div class="col-md-3">
                            Skipped Services: 
                            <br><br>
                            </div>
                            <div class="col-md-3">
                                <div class="row" color="primary" v-for="thisService in thiscustomer.skipped" :key="thisService">
                                {{thisService}}  <i class="pl-3 menu-icon fa fa-exclamation-triangle"></i>
                                </div>
                            </div>
                        </div>
                        <hr color="gray" v-if="thiscustomer.skipped.length > 0" />
                        <br>
                      <div  v-if="thiscustomer.tickets">
                          <div class="row">
                              <div class="col-md-3">
                              Recipient:
                              <br><br>
                              </div>
                              <div class="col-md-9">
                                  {{thiscustomer.tickets.requester}}
                              </div>
                          </div>
                          <div class="row"  v-if="thiscustomer.tickets.ccs">
                              <div class="col-md-3">
                              CC's:
                              </div>
                              <div class="col-md-9">
                                <div v-for="thisCC in thiscustomer.tickets.ccs" :key="thisCC">
                                {{thisCC}}<br>
                                </div>
                              </div>
                          </div>
                          <hr color="gray" v-if="thiscustomer.tickets.ccs" />
                          <div class="row"> 
                              <div class="col-md-3">
                              Email Message:
                              <br><br>
                              </div>
                              <div class="col-md-9">
                                  <p v-html="htmlComment(thiscustomer.tickets.htmlcomment)"></p>
                              </div>
                          </div>
                          <hr color="gray"/>
                        </div>
                        <div v-if="thiscustomer.sms">
                            <div v-for="thisSMS in thiscustomer.sms" :key="thisSMS.smsto">
                                <div class="row">
                                    <div class="col-md-3">
                                        SMS Recipient:
                                        <br><br>
                                    </div>
                                    <div class="col-md-9" >
                                        {{thisSMS.recepient}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        Receiving Number:
                                        <br><br>
                                    </div>
                                    <div class="col-md-9" >
                                        {{thisSMS.smsto}}
                                    </div>
                                </div>
                                <div class="row pb-3">
                                    <div class="col-md-3">
                                        Service ID's:
                                        <br><br>
                                        SMS Test: '{{thisSMS}}'
                                    </div>
                                    <div class="col-md-9" >
                                        <div class="row" v-for="thisService in thisSMS.services" :key="thisService">
                                            {{thisService}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                    SMS Text:
                                    <br><br>
                                    </div>
                                    <div class="col-md-9">
                                    {{thisSMS.message}}
                                    </div>
                                </div>
                                <hr color="gray"/>
                            </div>
                        </div>                    
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
            </section>
          </div>
        </vue-good-wizard>
      </div>
    </section>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import {GoodWizard} from "vue-good-wizard";
import Vue from "vue";
import Switches from 'vue-switches';

const TICKET_FETCH = "TICKET_FETCH";
const EVENT_CREATE = "EVENT_CREATE";

export default {
  name: "newEventModal",
  props: {
    modalID: String,
    serviceID: String
  },
  components: {
    Multiselect,
    Switches,
    "vue-good-wizard": GoodWizard
  },
  computed: {
    unixStartTime() {
        var StartValue = new Date(this.eventStartDate + " " + this.eventStartTime)
        return StartValue.getTime()/1000
    },
    unixEndTime() {
        var EndValue = new Date(this.eventEndDate + " " + this.eventEndTime)
        return EndValue.getTime()/1000
    },
    services() {
      var getServices = this.$store.getters.getServicesList
      var serviceIDs = []


      for (let i = 0; i < getServices.length; i++) {
        var thisService = {serviceid: '', name: '', linkstatus: {}}
        thisService.serviceid = getServices[i].serviceid
        thisService.name = getServices[i].serviceid + ": " + getServices[i].aendsitename + " to " + getServices[i].bendsitename
        thisService.linkstatus.overallstatus = ''
        serviceIDs.push(thisService)
      }

      return serviceIDs
    },
    pageOneValidation() {
      if(!this.eType.value || this.serviceList.length === 0 || !this.eventStartTime || !this.eventStartDate || !this.eTitle || this.eTitle.includes("[SUBURB]")  || !this.eDescription || this.eDescription.includes("[SUBURB]")  || !this.zTicket || !this.initiator ) {
        if(!this.eType.value || this.serviceList.length === 0 || !this.eventStartTime || !this.eventStartDate || !this.eTitle || !this.eDescription || !this.zTicket || !this.initiator){
          Vue.prototype.$snotify.warning("Event Fields are Missing!"), {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
            };
        }

        if(this.eTitle.includes("[SUBURB]") || this.eTitle.includes("[") || this.eTitle.includes("]") || this.eDescription.includes("[SUBURB]") || this.eDescription.includes("[") || this.eDescription.includes("]")){
          Vue.prototype.$snotify.warning("Event Form includes unfilled '[SUBURB]' template value!"), {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
            };
        }

        return false
      } else {
        if(this.eType.value !== 3) {
          if(!this.eventEndDate || !this.eventEndTime) {
            return false
          } else {
            return true
          }
        }
        return true
      }
    },
  },
  data() {
    return {
        finalStepLabel: "Exit",
        eTitle: "Fibre Works in [SUBURB]",
        eDescription: "The Fibre Asset Owner has informed us they will be conducting fibre works in the [SUBURB] area during the outage window.",
        eventTypes: [
        {name: "Scheduled Works", value: 1},
        {name: "Hazard", value: 2},
        {name: "Unplanned Outage", value: 3},
        ],
        eventPrefill: [
        {name: "Scheduled Fibre Works", title: "Fibre Works in [SUBURB]", description: "The Fibre Asset Owner has informed us they will be conducting fibre works in the [SUBURB] area during the outage window."},
        {name: "Scheduled Hazard Works", title: "Fibre Works in [SUBURB]", description: "The Fibre Asset Owner has informed us they will be conducting fibre works in the [SUBURB] area.  This will be in the vicinity of VERNet customer services, and may cause brief flaps or disruptions during this planned window."},
        {name: "Fibre Break in [SUBURB]", title: "Fibre Break in [SUBURB] area", description: "There is a suspected fibre break in the [SUBURB] area impacting customer services."},
        {name: "Hardware Fault in [SUBURB]", title: "Hardware Fault in [SUBURB] area", description: "VERNet have identified a hardware fault impacting customer services in the [SUBURB] area."},
        {name: "Third-Party Fibre Works", title: "Third-Party Fibre Works", description: "VERNet has been informed the fibre owner will be conducting scheduled fibre works that will impact customer services during their planned window."},
        {name: "Hardware Upgrades - VERNet", title: "Hardware Upgrade Works in [SUBURB]", description: "VERNet will be conducting planned hardware upgrade works in the [SUBURB] area during the outage window"},
        {name: "Hardware Upgrades - Third-Party", title: "Hardware Upgrade Works in [SUBURB]", description: "The Site Owner have informed VERNet they will be conducting planned hardware works in the [SUBURB] area that will impact customer services."},
        {name: "Software Upgrade - Upstream Provider", title: "Software Upgrade Works in [SUBURB]", description: "VERNet has been informed by the upstream provider that they will be conducting a software upgrade during the works window.  Customer services may experience a brief disruption to services during this time."},
        {name: "Software Upgrade - VERNet", title: "VERNet Software Upgrade Works in [SUBURB]", description: "VERNet will be conducting a software upgrade during the works window.  Customer services may experience a brief disruption to services during this time."},
        {name: "Power Works - Customer - Hazard", title: "Power Works in [SUBURB]", description: "VERNet have been informed of upcoming power works at our PoP site in the [SUBURB] area.  VERNet equipment will be powered by a generator during this window, and as such we do not anticipate a disruption to customer services while these works are conducted."},
        {name: "Power Works - Customer - Outage", title: "Power Works in [SUBURB]", description: "VERNet have been informed of upcoming power works at our PoP site in the [SUBURB] area.  VERNet equipment will be offline during this window, and as such customer services will be disrupted."},
        ],
        eventPrefillSelection: {name: "Scheduled Fibre Works", title: "Fibre Works in [SUBURB]", description: "The Fibre Asset Owner has informed us they will be conducting fibre works in the [SUBURB] area during the outage window."},
        eventPriority: [
        {name: "Critical", value: 1},
        {name: "High", value: 2},
        {name: "Medium", value: 3},
        {name: "Low", value: 4},
        {name: "Planning", value: 5}
        ],
        slaTypes: [
        {name: "Scheduled Outage", value: 1},
        {name: "Force Majeur", value: 2},
        {name: "Hardware Fault", value: 3},
        {name: "Power Failure", value: 4},
        {name: "Other", value: 5}
        ],
        slaTypeSelection: [],
        ePriority: [],
        serviceList: [],
        eType: [],
        eventCreated: {
          customers:[]
        },
        initiator: "",
        approver: "VERNet NOC",
        eventCustomers:[],
        tSubject: '',
        tText: '',
        tTroubleshooting: '',
        zTicket: 0,
        servicesAlert: false,
        serviceErrors: [],
        eventStartTime: '',
        eventStartDate: '',
        eventEndTime: '',
        eventEndDate: '',
        updateExisting: false,
        sendNotification: true,
        preFlight: false,
        generatedEvent: [],
        eventApproximate: false,
        steps: [
        {
            label: "Event Description",
            slot: "page1"
        },
        {
            label: "Preflight Confirmation",
            slot: "page2"
        },
        {
            label: "Summary",
            slot: "page3",
        }
        ]
    }
  },
  watch: {
    eventPrefillSelection(newValue) {
      this.eTitle = newValue.title
      this.eDescription = newValue.description
    },
  },
  methods: {
    customerName(code){
        return this.$store.getters.getCustomerProfileByCode(code)
    },
    hideModal() {
      this.serviceList = [],
      this.eType = [],
      this.eventCreated = {
          customers: []
      },
      this.eventCustomers = [],
      this.serviceErrors = [],
      this.tSubject = '',
      this.tText = '',
      this.initiator = "",
      this.approver = "VERNet NOC",
      this.ePriority = [],
      this.sendNotification = true,
      this.slaTypeSelection = [],
      this.tTroubleshooting = '',
      this.zTicket = 0,
      this.eventStartTime = '',
      this.eventStartDate = '',
      this.servicesAlert = false,
      this.eventEndTime = '',
      this.eventEndDate = '',
      this.updateExisting = false,
      this.preFlight = false,
      this.generatedEvent = [],
      this.eTitle = 'Fibre Works in [SUBURB]',
      this.eDescription = 'The Fibre asset owner has informed us they will be conducting fibre works in the [SUBURB] area during the outage window.',
      this.eventApproximate = false,
      this.$refs[this.modalID].hide()
    },
    createEvent(preflightCheck) {

      //  window.console.log("preflightCheck: " + preflightCheck)
        let tempServiceArray = []
      //  window.console.log("Service List: " + JSON.stringify(this.serviceList))
        this.serviceList.forEach((theService) => {
            tempServiceArray.push(theService.serviceid)
        })
        this.$store.dispatch(EVENT_CREATE, {
            number: this.zTicket,
            title: this.eTitle,
            starttime: this.unixStartTime,
            endtime: this.unixEndTime,
            type: this.eType.value,
            initiator: this.initiator,
            approver: this.approver,
            priority: this.ePriority.value,
            slatype: this.slaTypeSelection.value,
            services: tempServiceArray,
            updateexisting: this.updateExisting,
            description: this.eDescription,
            preflight: preflightCheck,
            sendnotification: this.sendNotification
        })
        .then(payload => {
            this.eventCustomers = payload.payload.customers;
            
            //window.console.log("Event: " + JSON.stringify(payload))
        })
          .catch(err => {
         //   window.console.log ("Error: " + JSON.stringify(err))
            Vue.prototype.$snotify.warning((err.message ? err.message : 'An error occurred while submitting this event for preflight. Please try again.'), {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
            });
        })
        /* if(preflightCheck == false)
        {
          window.console.log("Clearing Event Information...")
            this.serviceList =  [],
            this.eType = [],
            this.eventCreated = {
                customers:[]
            },
            this.eventCustomers = [],
            this.tSubject = '',
            this.tText = '',
            this.tTroubleshooting = '',
            this.zTicket = 0,
            this.eventStartTime = '',
            this.eventStartDate = '',
            this.eventEndTime = '',
            this.eventEndDate = '',
            this.updateExisting = false,
            this.eTitle = 'Fibre Works in [SUBURB]',
            this.eDescription = '[Provider] have informed us they will be conducting fibre works in the [LOCATION] area during the outage window.',
            this.eventApproximate = false
        } */


    },
    htmlComment(comment){
    //    window.console.log("Comment: " + comment)
        if(comment){
        var thisComment = comment.replace("\n", "")
        thisComment = thisComment.replace("\t", "")
  //      window.console.log("Revised Comment: " + thisComment)
        return thisComment
        }
        else{
          return ""
        }
    },
    getTicket(){
      this.serviceErrors = []
      this.servicesAlert = false
      if(this.$store.getters.getEventByNumber(this.zTicket)) {
        this.$router.replace('/event/' + this.$store.getters.getEventByNumber(this.zTicket).eventid)
      }
        this.$store.dispatch(TICKET_FETCH, {
        ticketID: this.zTicket
      })
          .then(payload => {
                if(payload.payload.tickettype == "task"){
                  this.eType = {name:"Scheduled Works", value: 1}
                  this.slaTypeSelection = {name: "Scheduled Outage", value: 1}
                  this.eventPrefillSelection = this.eventPrefill[0]              
                } else if(payload.payload.tickettype == "hazard"){
                  this.eType = {name:"Hazard", value: 2}
                  this.slaTypeSelection = {name: "Scheduled Outage", value: 1}
                  this.eventPrefillSelection = this.eventPrefill[1] 
                } else if(payload.payload.tickettype == "problem"){
                  this.eType = {name:"Unplanned Outage", value: 3}
                  this.eventPrefillSelection = this.eventPrefill[2] 
                }

                this.ePriority = {name: "Critical", value: 1}

                if(payload.payload.starttime > 0){
                  var payloadStartTime = new Date(payload.payload.starttime * 1000 )
                  this.eventStartTime = payloadStartTime.toLocaleTimeString('it-IT')
                  this.eventStartDate = payloadStartTime.getFullYear() + "-" + (payloadStartTime.getMonth() + 1)+ "-" + payloadStartTime.getDate();
                    }

                if(payload.payload.endtime > 0){
                  var payloadEndTime = new Date(payload.payload.endtime * 1000 )
                  this.eventEndTime = payloadEndTime.toLocaleTimeString('it-IT')
                  this.eventEndDate = payloadEndTime.getFullYear() + "-" + (payloadEndTime.getMonth() + 1)+ "-" + payloadEndTime.getDate();
                }

                if(payload.payload.services.length > 0){
                    payload.payload.services.forEach((payloadService) => {
                      if(this.serviceList.filter(s => s.serviceid === payloadService).length === 0){ //Check if service ID is already in list
                        var getService = this.$store.getters.getService(payloadService.trim())
                        if(!getService){
                          this.serviceErrors.push(payloadService + " does not exist in Database!")
                        } else if(!getService.status){
                          this.serviceErrors.push(payloadService + " is disabled!")
                        } else{
                          var thisService = {serviceid: '', name: '', linkstatus: {}}

                          thisService.serviceid = getService.serviceid
                          thisService.name = getService.serviceid + ": " + getService.aendsitename + " to " + getService.bendsitename
                          thisService.linkstatus.overallstatus = ''
                          this.serviceList.push(thisService)
                        }
                      }
                    })
                }  
          })
          .catch(err => {
           // window.console.log("Error: " + JSON.stringify(err))
            this.servicesAlert = true
            Vue.prototype.$snotify.warning((err.response ? err.response.message : 'Unable to retrieve services for Ticket ' + this.zTicket), {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
            });
          })
    },
    nextClicked(currentPage) {
      switch (currentPage) {
        case 0: {
          if (!this.pageOneValidation) {
            return false;
          } else {
            this.createEvent(true)
            return true
          }
        }
        case 1: {
            if (!this.eType.value) {
            return false;
          } else {
            if (this.eType.servicesmandatory === true) {
              return this.serviceList.length !== 0;
            }
            this.createEvent(false)
            return true
          }
        }
        default: {
          return true;
        }
      }
    },
    backClicked() {
      return true;
    },
    resize() {
      let evt = document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    },
    checkCurrentevents() {
      let returnArray = []
      this.serviceList.forEach((theservice) => {
        if (this.$store.getters.getServiceStatusList(theservice.serviceid).length > 0) {
          returnArray.push({service: theservice, event: this.$store.getters.getServiceStatusList(theservice.serviceid)})
        }
      });
      return returnArray
    }
  },
  beforeMount() {
    if(this.serviceID){
      var getService = this.$store.getters.getService(this.serviceID)
      var thisService = {serviceid: '', name: '', linkstatus: {}}

      thisService.serviceid = getService.serviceid
      thisService.name = getService.serviceid + ": " + getService.aendsitename + " to " + getService.bendsitename
      thisService.linkstatus.overallstatus = ''

      this.serviceList.push(thisService)


    }
  }
}
</script>

<style>
.btn.rounded-circle.text-dark {
  color: #888 !important;
}

.btn.rounded-circle.text-muted {
  color: #444 !important;
}
</style>