<template>
      <div  class="services-card card col-12 p-0">
        <div class="row container-fluid services-card-row justify-content-xl-between" :class="activeCheck">
          <div  class="col-2 servicetype rounded-topleft rounded-bottomleft" v-if="siteStatus === 3 || siteStatus === 6" v-bind:class="'bg-danger'">
                <div class="content">{{site.sitenumber}}</div>
            </div>
            <div  class="col-2 servicetype rounded-topleft rounded-bottomleft" v-else-if="siteStatus === 2 || siteStatus === 4" v-bind:class="'bg-primary'">
                <div class="content">{{site.sitenumber}}</div>
            </div>
            <div  class="col-2 servicetype rounded-topleft rounded-bottomleft" v-else-if="siteStatus === 1 || siteStatus === 5" v-bind:class="'bg-warning'">
                <div class="content">{{site.sitenumber}}</div>
            </div>
            <div  class="col-2  servicetype rounded-topleft rounded-bottomleft" v-else-if="siteStatus === 0 && !statusCheck()" v-bind:class="'bg-primary'">
                <div class="content">{{site.sitenumber}}</div>
            </div>
            <div  class="col-2  servicetype rounded-topleft rounded-bottomleft" v-else-if="siteStatus === 0 && statusCheck() && site.status == 1" v-bind:class="'bg-primary'">
                <div class="content">{{site.sitenumber}}</div>
            </div>
            <div  class="col-2  servicetype rounded-topleft rounded-bottomleft" v-else-if="siteStatus === 0 && statusCheck() && site.status == 0" v-bind:class="'bg-luminous'">
                <div class="content">{{site.sitenumber}}</div>
            </div>
          <router-link :to="'/site/'+site.siteid" class=" services-card-link  col-5 col-xl-4 serviceid pb-1 pb-xl-0">
            <div class="text-muted content display-5">
              {{ site.sitename }}
            </div>
          </router-link>
          <div class="col-3 site container-fluid d-none d-xl-inline">
            <div class="row vplssitename">
              <h4 class="text-muted">{{ affectedServices.length }} {{"service" | pluralize(affectedServices.length)}} impacted</h4>
            </div>
            <div class="row vplsservices">
              <ul>
                <li v-for="service in affectedServices" :key="service.serviceid" class="pb-1"><span
                    class="font-weight-bold">{{ service.serviceid }}</span>
                    <div v-if="service.aendsiteid === site.siteid" class="d-inline">
                      =&gt; ({{ service.bendsitename }})
                    </div>
                    <div v-else class="d-inline">
                      =&gt; ({{ service.aendsitename }})
                    </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-2 site container-fluid" v-if="siteStatus == 0">
                <div class="row sitenumber">
                    <h4 class="text-muted">Status</h4>
                </div>
                <div class="row sitename text-success" v-if="siteStatus == 0 && !statusCheck()">
                Online
                </div>
                <div class="row sitename text-success" v-else-if="siteStatus == 0 && statusCheck() && site.status == 1">
                Online
                </div>
                <div class="row sitename text-luminous" v-else-if="siteStatus == 0 && statusCheck() && site.status == 0">
                Disabled
                </div>
            </div>

            <router-link :to="'/event/' + eventsList[0].eventid" class=" col-2 services-card-link site container-fluid d-none d-xl-inline" v-else>
              <div class="row sitenumber">
                <h4 class="text-muted">Status</h4>
              </div>
              <div class="row sitename text-warning" v-if="siteStatus == 1">
                {{ impactedServices }} of {{ serviceList.length }} services currently experiencing Scheduled Works #{{ eventsList[0].number }}
              </div>
              <div class="row sitename text-info" v-else-if="siteStatus == 2">
                {{ impactedServices }} of {{ serviceList.length }} services within Hazard Window #{{ eventsList[0].number }}
              </div>
              <div class="row container-fluid sitename text-danger" v-if="siteStatus == 3">
                {{ impactedServices }} of {{ serviceList.length }} services impacted by Network Outage #{{ eventsList[0].number }}
              </div>
              <div class="row sitename text-warning" v-else-if="siteStatus == 4">
                All Site services are within Hazard Window #{{ eventsList[0].number }}
              </div>
              <div class="row sitename text-warning" v-else-if="siteStatus == 5">
                Site is Offline due to Scheduled Works #{{ eventsList[0].number }}
              </div>
              <div class="row container-fluid sitename text-danger" v-else-if="siteStatus == 6">
                Site is Offline due to Network Outage #{{ eventsList[0].number }}
              </div>
              <div class="row sitename text-success" v-else-if="siteStatus == 0 && !statusCheck()">
                Online
              </div>
              <div class="row sitename text-success" v-else-if="siteStatus == 0 && statusCheck() && site.status == 1">
                Online
              </div>
              <div class="row sitename text-luminous" v-else-if="siteStatus == 0 && statusCheck() && site.status == 0">
                Disabled
              </div>
            </router-link>
        </div>
      </div>


</template>

<script>
    export default {
        name: 'impactedSite',
        props: {
            siteID: String,
            eventID: String, //ImpactedSite can check against all events, or just the event it's related to in showevent.
        },
        computed:{
          site(){
              try 
              {
                return this.$store.getters.getSite(this.siteID);
              } 
              catch (error) 
              {
                return this.items;
              }
          },
          affectedServices(){
            try 
            {
              if(this.eventID){ //Just compare services against the event in question
                let testEvent = this.$store.getters.getEvent(this.eventID)
                let getServices = []
                if(this.$store.getters.getServicesListBySite(this.siteID)){
                  getServices = this.$store.getters.getServicesListBySite(this.siteID)
                }

                //Return all services that're both in the event and present at the site.
                return getServices.filter(eService => testEvent.services.includes(eService.serviceid));

              } else {  //Return all services affected by works or outages
                let affectedServiceList = [];
                let getServices = []

                if(this.$store.getters.getServicesListBySite(this.siteID)){
                  getServices = this.$store.getters.getServicesListBySite(this.siteID)
                }

                getServices.forEach( serviceelement => {
                  if(this.$store.getters.getAllEventsListBySID(serviceelement.serviceid).length > 0) {
                    affectedServiceList.push(this.$store.getters.getService(serviceelement.serviceid));
                  }
                });
                return affectedServiceList
              }
            } 
            catch (error) 
            {
              return []
            }
        },
        activeCheck(){
          if(this.site && Object.keys(this.site).includes("status")){
              if(this.site.status == 0){
                  return "inactiveElement"
              } else{
                  return "activeElement"
              }
          } else {
              return "activeElement"
          }
        },
        },
        data () {
          return {
            eventsList: [],
            siteStatus: 0,
            impactedServices: 0,
            serviceList: []
          }
        },
        beforeMount(){
          this.serviceList = this.$store.getters.getServicesListBySite(this.siteID)
          let siteCheck = this.$store.getters.getSiteStatus(this.serviceList)

          this.siteStatus = siteCheck.state
          this.eventsList = siteCheck.events
          this.impactedServices = siteCheck.serviceCount
        },
        methods:{
          statusCheck(){ //Check if site object has "status" in it
            if(this.site){
              return Object.keys(this.site).includes("status")
            } else {
              false
            }
          }
        }
   }
</script>