// store/index.js

import Vue from "vue";
import Vuex from "vuex";

import authtoken from "./modules/authtoken";
import userprofile from "./modules/userprofile";
import customerprofile from "./modules/customerprofile";
import services from "./modules/services";
import sites from "./modules/sites";
import events from "./modules/events";
import users from "./modules/users";
import contacts from "./modules/contacts";
import tickets from "./modules/tickets";
import colocation from "./modules/colocation";
import documentation from "./modules/documentation";
import notifications from "./modules/notifications";
import equipment from "./modules/equipment";
import globals from "./modules/globals";
import jobs from "./modules/jobs";
import faq from "./modules/customerfaq";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        authtoken,
        userprofile,
        customerprofile,
        services,
        sites,
        events,
        users,
        contacts,
        tickets,
        colocation,
        documentation,
        notifications,
        equipment,
        globals,
        jobs,
        faq
    }
});