<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          Copyright © 2024
          <a href="http://www.vernet.com.au/" target="_blank">VERNet</a>.
          All rights reserved.
        </span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          Version #{{ version_data }} | <a href="https://docs.api.vernet.net.au/" target="_blank">API Documentation</a>
        </span>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "app-footer",
  data: function () {
    return {
      version_data: process.env.VUE_APP_GIT_HASH
    }
  }
};
</script>
